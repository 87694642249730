<div class="container">
    <div class="image-background">
    </div>
    <div class="estimate-container">
        <p *ngIf="this.addressValid">Estimated Home Value for </p>
        <p *ngIf="this.addressValid">{{ this.addressLineOne| titlecase }}, {{this.addressLineTwo | titlecase }}</p>
        <p *ngIf="!this.addressValid">Estimate Not Found</p>
        <div class="card">
            <img *ngIf="this.lowerBound" src="../../assets/providedbyzillow.jpg">
            <div class="row" *ngIf="this.lowerBound">
                <span>{{ lowerBound | currency: 'USD' : 'symbol' : '1.0-0' }}</span>
                <span> - </span>
                <span>{{ upperBound | currency: 'USD' : 'symbol' : '1.0-0' }}</span>
            </div>
            <div class="col" *ngIf="!this.lowerBound">
                <span>Couldn’t find your property?</span>
                <button routerLink="/home-estimate-page" mat-raised-button color="primary">Try Another Search</button>
            </div>
            <h2 *ngIf="this.lowerBound">Zestimate</h2>
        </div>
        <div class="estimate-body">
            This is just an estimate. To get a better valuation please contact an agent.
            <button mat-raised-button color="accent" (click)="openZestimateModal()">Contact An Agent</button>
        </div>
    </div>
    <div class="body">
        <mat-card class="sell-card">
            <div class="sell-card-title">How To Sell Your Home</div>
            <img src="../../assets/stockPhotos/GetYourHomeReady.jpg" class="sell-card-img">
            <div class="sell-body">
                <p><strong>Get The Best Value</strong></p>
                <p><span>Our agents have top experience to ensure you get the best value for your home.</span></p>
                <p><strong>Get Maximum Exposure</strong></p>
                <p><span>Our agents will promote your home to hundreds of potential buyers.</span></p>
                <p><strong>Sell Your Home Faster</strong></p>
                <p><span>Our agents will sell your home quicker than any competitors</span></p>
            </div>
            <button routerLink="/seller-guide" mat-raised-button color="primary">
                Seller's Guide
            </button>
        </mat-card>
        <mat-card class="buy-card">            
            <div class="buy-card-title">How To Buy Your Next Home</div>
            <img src="../../assets/stockPhotos/CloseOnHome.jpg" class="buy-card-img">
            <div class="buy-body">
                <p><strong>Get Pre-Qualified</strong></p>
                <p><span>Connect with our preferred mortgage brokers to find the best mortgage product for you.</span></p>
                <p><strong>Get Familiar with the Market</strong></p>
                <p><span>Work with one of our trusted agents to set up an automatic search to ensure you never miss a property.</span></p>
                <p><strong>Close on Your New Home</strong></p>
                <p><span>From contract to closing, our agents will guide you every step of the way.</span></p>
            </div>
            <button routerLink="/buyer-guide"mat-raised-button color="primary">
                Buyer's Guide
            </button>
        </mat-card>
    </div>

</div>