<div class="container">
    <div class="top-row">
        <h3> Share this Listing</h3>
        <span [mat-dialog-close] class="cross">&times;</span>
    </div>
    <div class="body">
        <div class="address-label">
            Share a link to this Property
            <br>
            <b>{{ property["Address"]["Number"] }} {{ property["Address"]["Street"]| titlecase }}, {{ property["Address"]["City"] | titlecase}}, {{ property["Address"]["State"] | uppercase }}, {{ property["Address"]["Zip"] }}</b>
        </div>
        <button [cdkCopyToClipboard]="this.shareLink" (click)="isCopied = !isCopied; copiedStatus = 'Copied'" class="copyLinkBox">
            <div class="copyLinkText">
                {{ this.shareLink }}
            </div>
            <div class="copyLinkCopiedStatus">
                <span class="uncopied" *ngIf="!isCopied" >{{ copiedStatus }}</span>
                <span class="copied" *ngIf="isCopied" >{{ copiedStatus }}</span>
            </div>
        </button>
    </div>
</div>