import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class CanActivateChildGuard implements CanActivateChild {
  constructor(
      private dialog: MatDialog,
      private readonly router: Router,
      private readonly location: Location
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
      if (this.dialog.openDialogs.length > 0) {
          // fix navigation history, see github issue for more details
          // https://github.com/angular/angular/issues/13586
          const currentUrlTree = this.router.createUrlTree([], route);
          const currentUrl = currentUrlTree.toString();
          this.location.go(currentUrl);

          this.dialog.closeAll();
          return false;
      } else {
          return true;
      }
  }
}