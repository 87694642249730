<div class="container">
    <div class="top-white-bar">
        <svg (click)="closeModal()" id="exit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
    </div>
    <div class="gallery">
        <ngx-gallery *ngIf="galleryOptions" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
    </div>
    <div class="body">
        <div class="content">
            <div class="address-header">
                <h1 class="price-line">{{ property["Price"] | currency: 'USD' : 'symbol' : '1.0-0'}}</h1>
                <h1 class="address-line">{{ property["Address"]["Number"] }} {{ property["Address"]["Street"]| titlecase }}, {{ property["Address"]["City"] | titlecase}}, {{ property["Address"]["State"] | uppercase }}, {{ property["Address"]["Zip"] }}</h1>
                <h1 class="address-line"></h1>
            </div>
            <p class="light">{{property["Address"]["Subdivision"]}}</p>
            <p class="light">MLS#: {{property["ListingID"]}}</p>
            <div *ngIf="isResidentialProperty()" class="grouped-description">
                <div class="group-col">
                    <span>{{property["Bedrooms"]}}</span>
                    <span>Bedrooms</span>
                </div>
                <div class="group-col">
                    <span>{{property["FullBaths"]}}</span>
                    <span>Bathrooms</span>
                </div>
                <div class="group-col">
                    <span>{{property["Area"]}}</span>
                    <span>Square Feet</span>
                </div>
                <div class="group-col">
                    <span>.5</span>
                    <span>Acres</span>
                </div>
                <div class="group-col">
                    <span>{{property["YearBuilt"]}}</span>
                    <span>Year Built</span>
                </div>
                <div class="group-col">
                        <span>{{property["TotalRooms"]}}</span>
                        <span>Total Rooms</span>
                </div>
                <div class="group-col">
                    <span>{{property["Pool"]| titlecase}}</span>
                    <span>Pool</span>
                </div>
                <div class="group-col">
                        <span>{{property["Garage"| titlecase]}}</span>
                        <span>Garage</span>
                </div>
            </div>
            <div *ngIf="isIndustrialProperty()" class="grouped-description">
                <div class="group-col">
                    <span>{{ property["StatusCatID"] }}</span>
                    <span>Status</span>
                </div>
                <div class="group-col">
                    <span>{{property["Baths"]}}</span>
                    <span>Bathrooms</span>
                </div>
                <div class="group-col">
                    <span>{{property["Area"]}}</span>
                    <span>Building Area SqFt</span>
                </div>
                <!-- <div class="group-col">
                    <span>{{property["Area"]}} SqFt</span>
                    <span>Total area</span>
                </div> -->
                <div class="group-col">
                    <span>{{ property["Parking"] }}</span>
                    <span>Parking</span>
                </div>
                <div class="group-col">
                    <span>{{ property["Trailer Parking"] }}</span>
                    <span>Trailer Parking</span>
                </div>
                <div class="group-col">
                    <span>{{ property["Offices"] }}</span>
                    <span>Offices</span>
                </div>
                <div class="group-col">
                    <span>{{ property["Buildings"]}}</span>
                    <span>Buildings</span>
                </div>
            </div>
            <div *ngIf="isCommercialProperty()" class="grouped-description">
                <div class="group-col">
                    <span>{{ property["StatusCatID"] }}</span>
                    <span>Status</span>
                </div>
                <div class="group-col">
                    <span>{{property["Baths"]}}</span>
                    <span>Bathrooms</span>
                </div>
                <div class="group-col">
                    <span>{{property["Area"]}}</span>
                    <span>Building Area SqFt</span>
                </div>
                <!-- <div class="group-col">
                    <span>{{property["Area"]}} SqFt</span>
                    <span>Total area</span>
                </div> -->
                <div class="group-col">
                    <span>{{ property["Parking"] }}</span>
                    <span>Parking</span>
                </div>
                <div class="group-col">
                    <span>{{ property["Total Units"] }}</span>
                    <span>Total Units</span>
                </div>

            </div>
            <div *ngIf="isLandProperty()" class="grouped-description-land">
                <div class="group-col">
                    <span>{{property["Area"]}}</span>
                    <span>Area</span>
                </div>
                <div class="group-col">
                    <span>{{property["StatusCatID"]}}</span>
                    <span>Status</span>
                </div>
                <div class="group-col">
                    <span>{{property["SaleRent"]}}</span>
                    <span>For Sale or Rent</span>
                </div>
            </div>
            <div class="remark" *ngIf="property['Remarks']">
                <span>Remarks: </span> <p>{{ property["Remarks"] }}</p>
            </div>
        </div>
        <mat-card class="contact-box">
            <span>I'm Interested</span>
            <div class="socials-container">
                <div class="like-button">
                    <button mat-icon-button width="24px" heigh="24px" (click)="toggleFavorite()">
                        <mat-icon width="24px" heigh="24px" color="warn"> {{ this.property["icon_label"] }} </mat-icon>
                    </button>
                    <span>
                        {{ text_label }}
                    </span>
                </div>
                <div class="share-button">
                    <button mat-icon-button width="24px" height="24px" (click)="openShareModal()">
                        <mat-icon width="24px" heigh="24px" color="primary">share</mat-icon>
                    </button>
                    <span>
                        Share
                    </span>
                </div>
            </div>
            <button id="submit-contact-button" mat-raised-button color="primary" (click)="openContactModal()">Contact</button>
            <button id="request-tour-button" mat-raised-button color="accent" (click)="openTourModal()">Request A Tour</button>

        </mat-card>
        <div class="map" *ngIf="property['Coordinates'][1]!==1">
            <agm-map [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]" [zoom]="zoom">
                <agm-marker *ngIf="property['Coordinates']"
                            [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
                            [iconUrl]="mapIcon" animation="DROP" [label]="{color: 'white', text: '$' + property['Price_Suffix']}">
                </agm-marker>
            </agm-map>
        </div>
    </div>
    <div class="property-footer"> 
        <div>
            Courtesty of Broker {{ property["OfficeName"]}}
        </div>
        <div>
            {{ property["OfficeEmail"]}}
        </div>
    </div>
</div>