import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LoginPopupService } from './login-popup.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public authService: AuthService, public loginPopup: LoginPopupService, public router: Router, public route: ActivatedRoute) { }

  async canActivate() {
     if (!await this.authService.checkAuthenticated()) {
      console.log(this.router.url);
//      await this.router.navigate(['login'], {queryParams: {returnUrl: this.router.url}});
      this.router.navigate(['home']);
      this.loginPopup.triggerLogin();
      return false;
    }
    return true;
  }
}
