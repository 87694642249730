<div class="container">
    <div class="top-row">
        <h3> Contact An Agent</h3>
        <span [mat-dialog-close] class="cross">&times;</span>
    </div>
    <form [formGroup]="contactForm" (ngSubmit)="submitForm(contactForm,formDirective)" #formDirective="ngForm">
        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName = "firstName">
            <mat-error *ngIf="contactForm.controls['firstName'].hasError('required')">Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName = "lastName">
            <mat-error *ngIf="contactForm.controls['lastName'].hasError('required')">Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName = "email">
            <mat-error *ngIf="contactForm.controls['email'].hasError('required')">Required</mat-error>
            <mat-error *ngIf="contactForm.controls['email'].hasError('email')">Enter a valid email</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput type="tel" formControlName = "phone">
            <mat-error *ngIf="contactForm.controls['phone'].hasError('required')">Required</mat-error>
            <mat-error *ngIf="contactForm.controls['phone'].hasError('pattern')">Enter a valid phone number (###-###-####)</mat-error>
        </mat-form-field>
        <mat-form-field  appearance="outline">
            <mat-label>Working With An Agent?</mat-label>
            <mat-select panelCLass="options" formControlName="agent">
                <mat-option *ngFor="let agent of agents" [value]="agent">
                  {{agent}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="contactForm.controls['agent'].hasError('required')">Select an agent</mat-error>
        </mat-form-field>
        <button id="submit-info" type="submit" color="accent" mat-flat-button [disabled]="!this.contactForm.valid">Submit</button>
        <span *ngIf="failedSubmit">Failed to submit form, please try again later.</span>
    </form>
</div>
