import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoginPopupService {

  constructor() { }
  public subject = new BehaviorSubject<any>('');

  triggerLogin() {
    this.subject.next('yeet');
  }
}
