<div class="guide">
    <div class="header-photo">
        <div class="guide-header-column">
            <div class="column-item bold">Seller's Guide</div>
            <hr>
            <div class="column-item bold-light">From contract to closing, learn more about the home selling experience.</div>
        </div>
    </div>
    <div class="body-introduction">
        <p>
            Simplifying the process by breaking it down in these 5 steps ensures a quick, easy, and enjoyable home-buying experience you will never forget.
        </p>
    </div>
    <app-sell-guide-card></app-sell-guide-card>
    <div class="body-end">
        <h1>Advertising your Property</h1>
        <p>
            Our agents take advantage of social media technology and local marketing strategies, and have the capability and local-market competence to help sell your property. Benefits of being advertised by us:
        </p>
        <p><strong>Online Exposure of Your Property</strong></p>
        <p>
            The online marketing of your property will enhance its exposure to as many buyers in the market pool as possible. Your listing will feature information specific to your property and the vicinity around it, as well as a high-quality property image. First impressions speak the loudest, and the property image gives potential buyers an instant first-look, which saves time and piques interest on an aesthetic and visual level.
        </p>
        <p><strong>Mobile, On-the-Go Exposure</strong></p>
        <p>
            Interested buyers will have the ability to utilize the same great search technology available on our website while on their smart device! This increases the opportunity for your property to be seen by potential house hunters at any time and anywhere.
        </p>
    </div>
</div>