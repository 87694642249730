import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { onErrorResumeNextStatic } from 'rxjs/internal/operators/onErrorResumeNext';
import { EstimateModalComponent } from '../estimate-modal/estimate-modal.component';
@Component({
  selector: 'app-zestimate',
  templateUrl: './zestimate.component.html',
  styleUrls: ['./zestimate.component.css']
})
export class ZestimateComponent implements OnInit {
  lat: number;
  long: number;
  defLat = 27.5061111;
  defLong = -99.5072222;
  zoom = 16;
  defZoom = 12;

  lowerBound: number
  upperBound: number
  middleEstimate: number
  addressForCoord: string
  address: string
  addressLineOne: string
  addressLineTwo: string
  addressValid: boolean = false;
  constructor(private route: ActivatedRoute, private http: HttpClient, public dialog: MatDialog) { }


  ngOnInit(): void {
    this.route.queryParams.subscribe(_param => {
      this.address = _param["address"]
      this.addressForCoord = this.address.split("+").join(" ")
      this.addressLineOne = this.address.substring( 0, this.address.indexOf("laredo")).split("+").join(" ")
      this.addressLineTwo = this.address.substring(this.address.indexOf("laredo"), this.address.length).split("+").join(" ").replace(" tx ",", TX, ").replace("laredo","Laredo")
    })
    try {
      this.http.get(environment.API_URL+"coordinates/"+this.addressForCoord).subscribe(response => {
        this.lat = response[1]
        this.long = response[0]
        this.zoom = this.zoom
        this.addressValid = true;
      });
    }
    catch(err) {
      if(err["status"]===404) {
        this.addressValid = false;
      }
    }
    try {
      this.http.get(environment.API_URL+"zestimate/"+this.address).subscribe(response => {
        this.lowerBound = response["lower"]
        this.upperBound = response["upper"]
        this.middleEstimate = response["zestimate"]
      })
    }
    catch(err) {
      console.log(err["Object"])
      if(err["Object"]["status"]===404) {
        this.addressValid = false;
      }
    }

    
  }
  openZestimateModal() {
    const dialogRef = this.dialog.open(EstimateModalComponent, {
      width: 'fit-content',
      height: 'fit-content',
      data: this.addressForCoord
    })
  }
}
