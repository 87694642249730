import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {

  founder;
  agents: Array<Object> = new Array<Object>();

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get(environment.API_URL + "agents")
        .subscribe(response => {
          const res = response as JSON;
          this.founder = res["founder"]
          let objects = res["agents"];
          objects.forEach(element => {
            this.agents.push(element)
          });
          console.log(this.agents);
        });
  }

}
