<div id="forgot-password-modal">
    <span class="cross" mat-dialog-close>&times;</span>

    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
        <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>Account Email</mat-label>
            <input matInput formControlName = "email" required>
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">Enter your email </mat-error>
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('email')">This does not seem to be a valid email</mat-error>
        </mat-form-field>
        <span *ngIf="resetEmailSent && !invalidEmailProvided">Please check you email to reset your password</span>
        <span class="invalid-login" *ngIf="invalidEmailProvided && !resetEmailSent">Email did not match a registered account</span>
        <button class="login-btn" type="submit" mat-flat-button color="accent" [disabled]="!forgotPasswordForm.valid">Submit</button>
    </form>
</div>