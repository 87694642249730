<div class="hero-photo">
    <h1 class="bold">
        Finding Home Was Never This Easy
    </h1>
    <div class="search-bar">
        <input ngx-google-places-autocomplete [options]="options" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
        <button mat-flat-button color="accent" (click)="submitAddress()">Search</button>
    </div>
</div>

<div class="agents">
    <img src="../../assets/gabali logo.png" id="gabali-logo" alt="">

    <div class="agents-text">
        <h4>Meet us</h4>
        <h2>Gabali Realty</h2>
        <p>
            Gabali Realty is a local, boutique real estate company specializing in making the real estate buying and selling process as smooth and seamless as possible! Contact us today to get started!
        </p>
        <button routerLink="/agents" routerLinkActive="active"> See Agents </button>
    </div>

    <img routerLink="/agents" routerLinkActive="active" src="../../assets/agent-imgs/elijah-marisol-nora.png" alt="">
</div>
<div class="carousel carousel-slider center">
    <div class="carousel-fixed-new">
        <div class="review-header">
            <div class="star-ratings-css">
                <div class="star-ratings-css-top" [style]="'width: ' + avgStarPercent + '%'"><span class="review-star" *ngFor="let number of [].constructor(5)">★</span></div>
                <div class="star-ratings-css-bottom"><span *ngFor="let number of [].constructor(5)">★</span></div>
            </div>
            <h2> {{ avgStarRating }} stars across {{ totalReviewCount }} reviews.</h2>
        </div>
    </div>
    <div class="review-cards-carousel">
        <div class="carousel-item card-container" href="#one!">
            <div class="review-card">
            <div class="review-card-header">
                <p>Provided by</p>
                <svg viewBox="0 0 1911 400" focusable="false" width="200" height="60">
                    <g fill="blue">
                        <path d="M1460.4 148.6h77.8l22.5 73.7c7.5 24.4 20.1 70.2 20.1 70.2a.6.6 0 0 0 1.1 0s13-46.7 20.5-70.2l23.6-73.7h57.4l23.3 73.7c7.8 25.2 20 70.2 20 70.2a.6.6 0 0 0 1.1 0s12.7-45.6 20-70.2l22.6-73.7h72.3L1764.5 392h-71.1l-19.4-61c-7.5-24-20.6-70.6-20.6-70.6a.6.6 0 0 0-1.1 0s-13.1 45.7-21 70.2l-19.7 61.6h-72.4zM936.8 19.9h78.7v372.2h-78.7zM1073.8 19.9h78.7v372.2h-78.7zM1380.7 271.2c0-36.3-25.9-61-56.5-61-31.3 0-57.2 24.7-57.2 61 0 36.5 25.9 60.9 57.2 60.9 30.6 0 56.5-24.4 56.5-61m-189.2 0c0-74 59.7-128.7 132.7-128.7 72.3 0 132 54.8 132 128.8 0 74.2-59.7 129-132 129-73 0-132.7-54.8-132.7-129M481.5 54.4V126h139c.7 0 .9.5.6 1L481.4 364.5c-3.4 6.2-4.2 7.5-4.2 13.6v14h271v-71.6H597.7a.6.6 0 0 1-.5-1L738.5 79c1.1-2.3 2.3-4 2.3-9.6v-15zM798.4 148.6H877V392h-78.7zm39.3-122c24.6 0 44.8 19.8 44.8 44.2S862.3 115 837.7 115 793 95.2 793 70.8s20.3-44.2 44.8-44.2 "></path>
                        <path d="M242.2 106.3c1.8-.4 2.6.2 3.7 1.4 6.1 7 26 31.1 31.4 37.7a2.1 2.1 0 0 1-.5 3.2 714.8 714.8 0 0 0-109.3 107c-.5.6-.1.7.2.5 43.3-18.6 144.7-48.2 190.5-56.6v-57.9L179.2.2.2 141.6V205c55.5-33 183.9-84.1 242-98.6z"></path>
                        <path d="M96.6 349.5a3 3 0 0 1-3.8-.6l-33.5-39.8c-.9-1-1-1.6.2-3.4C85.3 268 138 209 171.5 184.1c.7-.5.5-1-.2-.7C136.3 195 37 237.6 0 258.4v135.2h358.2V263.5c-49 8.3-195.1 51.8-261.6 86z"></path>
                        <path d="M1895.2 113.9c0-7-4.9-12-11.7-12H1871v34h5.6v-10.1h5.5l6.2 10.2h6.4l-6.7-11c4.3-1.7 7.2-5.8 7.2-11.1zm-12 6.9h-6.6V107h6.6c3.7 0 6.4 3 6.4 7s-2.7 6.8-6.4 6.8z"></path>
                        <path d="M1881.8 90.2a29.4 29.4 0 1 0 29.4 29.3 29.4 29.4 0 0 0-29.4-29.3zm0 53a23.7 23.7 0 1 1 23.8-23.7 23.8 23.8 0 0 1-23.8 23.8z"></path>
                    </g>
                </svg>
                <a href="http://www.zillow.com/profile/Gabali-Realty/"> See more reviews </a>
            </div>
            <div class="review-text" *ngIf="zillowAPICalled | async" >
                <span> {{ zillowReviews[0]["reviewDate"] }} - {{ zillowReviews[0]["reviewer"] }} </span>
                <span> {{ zillowReviews[0]["reviewSummary"] }} </span>
                <div class="description">
                    <p> {{ zillowReviews[0]["description"] }} </p>
                    <a [href]="zillowReviews[0]['reviewURL']"> Read more </a>
                </div>
            </div>
        </div> 
        </div>
        <div class="carousel-item card-container" href="#two!">
            <div class="review-card">
            <div class="review-card-header">
                <p>Provided by</p>
                <svg viewBox="0 0 1911 400" focusable="false" width="200" height="60">
                    <g fill="blue">
                        <path d="M1460.4 148.6h77.8l22.5 73.7c7.5 24.4 20.1 70.2 20.1 70.2a.6.6 0 0 0 1.1 0s13-46.7 20.5-70.2l23.6-73.7h57.4l23.3 73.7c7.8 25.2 20 70.2 20 70.2a.6.6 0 0 0 1.1 0s12.7-45.6 20-70.2l22.6-73.7h72.3L1764.5 392h-71.1l-19.4-61c-7.5-24-20.6-70.6-20.6-70.6a.6.6 0 0 0-1.1 0s-13.1 45.7-21 70.2l-19.7 61.6h-72.4zM936.8 19.9h78.7v372.2h-78.7zM1073.8 19.9h78.7v372.2h-78.7zM1380.7 271.2c0-36.3-25.9-61-56.5-61-31.3 0-57.2 24.7-57.2 61 0 36.5 25.9 60.9 57.2 60.9 30.6 0 56.5-24.4 56.5-61m-189.2 0c0-74 59.7-128.7 132.7-128.7 72.3 0 132 54.8 132 128.8 0 74.2-59.7 129-132 129-73 0-132.7-54.8-132.7-129M481.5 54.4V126h139c.7 0 .9.5.6 1L481.4 364.5c-3.4 6.2-4.2 7.5-4.2 13.6v14h271v-71.6H597.7a.6.6 0 0 1-.5-1L738.5 79c1.1-2.3 2.3-4 2.3-9.6v-15zM798.4 148.6H877V392h-78.7zm39.3-122c24.6 0 44.8 19.8 44.8 44.2S862.3 115 837.7 115 793 95.2 793 70.8s20.3-44.2 44.8-44.2 "></path>
                        <path d="M242.2 106.3c1.8-.4 2.6.2 3.7 1.4 6.1 7 26 31.1 31.4 37.7a2.1 2.1 0 0 1-.5 3.2 714.8 714.8 0 0 0-109.3 107c-.5.6-.1.7.2.5 43.3-18.6 144.7-48.2 190.5-56.6v-57.9L179.2.2.2 141.6V205c55.5-33 183.9-84.1 242-98.6z"></path>
                        <path d="M96.6 349.5a3 3 0 0 1-3.8-.6l-33.5-39.8c-.9-1-1-1.6.2-3.4C85.3 268 138 209 171.5 184.1c.7-.5.5-1-.2-.7C136.3 195 37 237.6 0 258.4v135.2h358.2V263.5c-49 8.3-195.1 51.8-261.6 86z"></path>
                        <path d="M1895.2 113.9c0-7-4.9-12-11.7-12H1871v34h5.6v-10.1h5.5l6.2 10.2h6.4l-6.7-11c4.3-1.7 7.2-5.8 7.2-11.1zm-12 6.9h-6.6V107h6.6c3.7 0 6.4 3 6.4 7s-2.7 6.8-6.4 6.8z"></path>
                        <path d="M1881.8 90.2a29.4 29.4 0 1 0 29.4 29.3 29.4 29.4 0 0 0-29.4-29.3zm0 53a23.7 23.7 0 1 1 23.8-23.7 23.8 23.8 0 0 1-23.8 23.8z"></path>
                    </g>
                </svg>
                <a href="http://www.zillow.com/profile/Gabali-Realty/"> See more reviews </a>
            </div>
            <div class="review-text" *ngIf="zillowAPICalled | async" >
                <span> {{ zillowReviews[1]["reviewDate"] }} - {{ zillowReviews[1]["reviewer"] }} </span>
                <span> {{ zillowReviews[1]["reviewSummary"] }} </span>
                <div class="description">
                    <p> {{ zillowReviews[1]["description"] }} </p>
                    <a [href]="zillowReviews[1]['reviewURL']"> Read more </a>
                </div>
            </div>
        </div> 
        </div>
        <div class="carousel-item card-container" href="#three!">
            <div class="review-card">
            <div class="review-card-header">
                <p>Provided by</p>
                <svg viewBox="0 0 1911 400" focusable="false" width="200" height="60">
                    <g fill="blue">
                        <path d="M1460.4 148.6h77.8l22.5 73.7c7.5 24.4 20.1 70.2 20.1 70.2a.6.6 0 0 0 1.1 0s13-46.7 20.5-70.2l23.6-73.7h57.4l23.3 73.7c7.8 25.2 20 70.2 20 70.2a.6.6 0 0 0 1.1 0s12.7-45.6 20-70.2l22.6-73.7h72.3L1764.5 392h-71.1l-19.4-61c-7.5-24-20.6-70.6-20.6-70.6a.6.6 0 0 0-1.1 0s-13.1 45.7-21 70.2l-19.7 61.6h-72.4zM936.8 19.9h78.7v372.2h-78.7zM1073.8 19.9h78.7v372.2h-78.7zM1380.7 271.2c0-36.3-25.9-61-56.5-61-31.3 0-57.2 24.7-57.2 61 0 36.5 25.9 60.9 57.2 60.9 30.6 0 56.5-24.4 56.5-61m-189.2 0c0-74 59.7-128.7 132.7-128.7 72.3 0 132 54.8 132 128.8 0 74.2-59.7 129-132 129-73 0-132.7-54.8-132.7-129M481.5 54.4V126h139c.7 0 .9.5.6 1L481.4 364.5c-3.4 6.2-4.2 7.5-4.2 13.6v14h271v-71.6H597.7a.6.6 0 0 1-.5-1L738.5 79c1.1-2.3 2.3-4 2.3-9.6v-15zM798.4 148.6H877V392h-78.7zm39.3-122c24.6 0 44.8 19.8 44.8 44.2S862.3 115 837.7 115 793 95.2 793 70.8s20.3-44.2 44.8-44.2 "></path>
                        <path d="M242.2 106.3c1.8-.4 2.6.2 3.7 1.4 6.1 7 26 31.1 31.4 37.7a2.1 2.1 0 0 1-.5 3.2 714.8 714.8 0 0 0-109.3 107c-.5.6-.1.7.2.5 43.3-18.6 144.7-48.2 190.5-56.6v-57.9L179.2.2.2 141.6V205c55.5-33 183.9-84.1 242-98.6z"></path>
                        <path d="M96.6 349.5a3 3 0 0 1-3.8-.6l-33.5-39.8c-.9-1-1-1.6.2-3.4C85.3 268 138 209 171.5 184.1c.7-.5.5-1-.2-.7C136.3 195 37 237.6 0 258.4v135.2h358.2V263.5c-49 8.3-195.1 51.8-261.6 86z"></path>
                        <path d="M1895.2 113.9c0-7-4.9-12-11.7-12H1871v34h5.6v-10.1h5.5l6.2 10.2h6.4l-6.7-11c4.3-1.7 7.2-5.8 7.2-11.1zm-12 6.9h-6.6V107h6.6c3.7 0 6.4 3 6.4 7s-2.7 6.8-6.4 6.8z"></path>
                        <path d="M1881.8 90.2a29.4 29.4 0 1 0 29.4 29.3 29.4 29.4 0 0 0-29.4-29.3zm0 53a23.7 23.7 0 1 1 23.8-23.7 23.8 23.8 0 0 1-23.8 23.8z"></path>
                    </g>
                </svg>
                <a href="http://www.zillow.com/profile/Gabali-Realty/"> See more reviews </a>
            </div>
            <div class="review-text" *ngIf="zillowAPICalled | async" >
                <span> {{ zillowReviews[2]["reviewDate"] }} - {{ zillowReviews[2]["reviewer"] }} </span>
                <span> {{ zillowReviews[2]["reviewSummary"] }} </span>
                <div class="description">
                    <p> {{ zillowReviews[2]["description"] }} </p>
                    <a [href]="zillowReviews[2]['reviewURL']"> Read more </a>
                </div>
            </div>
        </div> 
        </div>
        <div class="carousel-item card-container" href="#four!">
            <div class="review-card">
            <div class="review-card-header">
                <p>Provided by</p>
                <svg viewBox="0 0 1911 400" focusable="false" width="200" height="60">
                    <g fill="blue">
                        <path d="M1460.4 148.6h77.8l22.5 73.7c7.5 24.4 20.1 70.2 20.1 70.2a.6.6 0 0 0 1.1 0s13-46.7 20.5-70.2l23.6-73.7h57.4l23.3 73.7c7.8 25.2 20 70.2 20 70.2a.6.6 0 0 0 1.1 0s12.7-45.6 20-70.2l22.6-73.7h72.3L1764.5 392h-71.1l-19.4-61c-7.5-24-20.6-70.6-20.6-70.6a.6.6 0 0 0-1.1 0s-13.1 45.7-21 70.2l-19.7 61.6h-72.4zM936.8 19.9h78.7v372.2h-78.7zM1073.8 19.9h78.7v372.2h-78.7zM1380.7 271.2c0-36.3-25.9-61-56.5-61-31.3 0-57.2 24.7-57.2 61 0 36.5 25.9 60.9 57.2 60.9 30.6 0 56.5-24.4 56.5-61m-189.2 0c0-74 59.7-128.7 132.7-128.7 72.3 0 132 54.8 132 128.8 0 74.2-59.7 129-132 129-73 0-132.7-54.8-132.7-129M481.5 54.4V126h139c.7 0 .9.5.6 1L481.4 364.5c-3.4 6.2-4.2 7.5-4.2 13.6v14h271v-71.6H597.7a.6.6 0 0 1-.5-1L738.5 79c1.1-2.3 2.3-4 2.3-9.6v-15zM798.4 148.6H877V392h-78.7zm39.3-122c24.6 0 44.8 19.8 44.8 44.2S862.3 115 837.7 115 793 95.2 793 70.8s20.3-44.2 44.8-44.2 "></path>
                        <path d="M242.2 106.3c1.8-.4 2.6.2 3.7 1.4 6.1 7 26 31.1 31.4 37.7a2.1 2.1 0 0 1-.5 3.2 714.8 714.8 0 0 0-109.3 107c-.5.6-.1.7.2.5 43.3-18.6 144.7-48.2 190.5-56.6v-57.9L179.2.2.2 141.6V205c55.5-33 183.9-84.1 242-98.6z"></path>
                        <path d="M96.6 349.5a3 3 0 0 1-3.8-.6l-33.5-39.8c-.9-1-1-1.6.2-3.4C85.3 268 138 209 171.5 184.1c.7-.5.5-1-.2-.7C136.3 195 37 237.6 0 258.4v135.2h358.2V263.5c-49 8.3-195.1 51.8-261.6 86z"></path>
                        <path d="M1895.2 113.9c0-7-4.9-12-11.7-12H1871v34h5.6v-10.1h5.5l6.2 10.2h6.4l-6.7-11c4.3-1.7 7.2-5.8 7.2-11.1zm-12 6.9h-6.6V107h6.6c3.7 0 6.4 3 6.4 7s-2.7 6.8-6.4 6.8z"></path>
                        <path d="M1881.8 90.2a29.4 29.4 0 1 0 29.4 29.3 29.4 29.4 0 0 0-29.4-29.3zm0 53a23.7 23.7 0 1 1 23.8-23.7 23.8 23.8 0 0 1-23.8 23.8z"></path>
                    </g>
                </svg>
                <a href="http://www.zillow.com/profile/Gabali-Realty/"> See more reviews </a>
            </div>
            <div class="review-text" *ngIf="zillowAPICalled | async" >
                <span> {{ zillowReviews[3]["reviewDate"] }} - {{ zillowReviews[3]["reviewer"] }} </span>
                <span> {{ zillowReviews[3]["reviewSummary"] }} </span>
                <div class="description">
                    <p> {{ zillowReviews[3]["description"] }} </p>
                    <a [href]="zillowReviews[3]['reviewURL']"> Read more </a>
                </div>
            </div>
        </div> 
        </div>
        <div class="carousel-item card-container" href="#five!">
            <div class="review-card">
            <div class="review-card-header">
                <p>Provided by</p>
                <svg viewBox="0 0 1911 400" focusable="false" width="200" height="60">
                    <g fill="blue">
                        <path d="M1460.4 148.6h77.8l22.5 73.7c7.5 24.4 20.1 70.2 20.1 70.2a.6.6 0 0 0 1.1 0s13-46.7 20.5-70.2l23.6-73.7h57.4l23.3 73.7c7.8 25.2 20 70.2 20 70.2a.6.6 0 0 0 1.1 0s12.7-45.6 20-70.2l22.6-73.7h72.3L1764.5 392h-71.1l-19.4-61c-7.5-24-20.6-70.6-20.6-70.6a.6.6 0 0 0-1.1 0s-13.1 45.7-21 70.2l-19.7 61.6h-72.4zM936.8 19.9h78.7v372.2h-78.7zM1073.8 19.9h78.7v372.2h-78.7zM1380.7 271.2c0-36.3-25.9-61-56.5-61-31.3 0-57.2 24.7-57.2 61 0 36.5 25.9 60.9 57.2 60.9 30.6 0 56.5-24.4 56.5-61m-189.2 0c0-74 59.7-128.7 132.7-128.7 72.3 0 132 54.8 132 128.8 0 74.2-59.7 129-132 129-73 0-132.7-54.8-132.7-129M481.5 54.4V126h139c.7 0 .9.5.6 1L481.4 364.5c-3.4 6.2-4.2 7.5-4.2 13.6v14h271v-71.6H597.7a.6.6 0 0 1-.5-1L738.5 79c1.1-2.3 2.3-4 2.3-9.6v-15zM798.4 148.6H877V392h-78.7zm39.3-122c24.6 0 44.8 19.8 44.8 44.2S862.3 115 837.7 115 793 95.2 793 70.8s20.3-44.2 44.8-44.2 "></path>
                        <path d="M242.2 106.3c1.8-.4 2.6.2 3.7 1.4 6.1 7 26 31.1 31.4 37.7a2.1 2.1 0 0 1-.5 3.2 714.8 714.8 0 0 0-109.3 107c-.5.6-.1.7.2.5 43.3-18.6 144.7-48.2 190.5-56.6v-57.9L179.2.2.2 141.6V205c55.5-33 183.9-84.1 242-98.6z"></path>
                        <path d="M96.6 349.5a3 3 0 0 1-3.8-.6l-33.5-39.8c-.9-1-1-1.6.2-3.4C85.3 268 138 209 171.5 184.1c.7-.5.5-1-.2-.7C136.3 195 37 237.6 0 258.4v135.2h358.2V263.5c-49 8.3-195.1 51.8-261.6 86z"></path>
                        <path d="M1895.2 113.9c0-7-4.9-12-11.7-12H1871v34h5.6v-10.1h5.5l6.2 10.2h6.4l-6.7-11c4.3-1.7 7.2-5.8 7.2-11.1zm-12 6.9h-6.6V107h6.6c3.7 0 6.4 3 6.4 7s-2.7 6.8-6.4 6.8z"></path>
                        <path d="M1881.8 90.2a29.4 29.4 0 1 0 29.4 29.3 29.4 29.4 0 0 0-29.4-29.3zm0 53a23.7 23.7 0 1 1 23.8-23.7 23.8 23.8 0 0 1-23.8 23.8z"></path>
                    </g>
                </svg>
                <a href="http://www.zillow.com/profile/Gabali-Realty/"> See more reviews </a>
            </div>
            <div class="review-text" *ngIf="zillowAPICalled | async" >
                <span> {{ zillowReviews[4]["reviewDate"] }} - {{ zillowReviews[4]["reviewer"] }} </span>
                <span> {{ zillowReviews[4]["reviewSummary"] }} </span>
                <div class="description">
                    <p> {{ zillowReviews[4]["description"] }} </p>
                    <a [href]="zillowReviews[4]['reviewURL']"> Read more </a>
                </div>
            </div>
        </div> 
        </div>
        <div class="carousel-item card-container" href="#six!">
            <div class="review-card">
            <div class="review-card-header">
                <p>Provided by</p>
                <svg viewBox="0 0 1911 400" focusable="false" width="200" height="60">
                    <g fill="blue">
                        <path d="M1460.4 148.6h77.8l22.5 73.7c7.5 24.4 20.1 70.2 20.1 70.2a.6.6 0 0 0 1.1 0s13-46.7 20.5-70.2l23.6-73.7h57.4l23.3 73.7c7.8 25.2 20 70.2 20 70.2a.6.6 0 0 0 1.1 0s12.7-45.6 20-70.2l22.6-73.7h72.3L1764.5 392h-71.1l-19.4-61c-7.5-24-20.6-70.6-20.6-70.6a.6.6 0 0 0-1.1 0s-13.1 45.7-21 70.2l-19.7 61.6h-72.4zM936.8 19.9h78.7v372.2h-78.7zM1073.8 19.9h78.7v372.2h-78.7zM1380.7 271.2c0-36.3-25.9-61-56.5-61-31.3 0-57.2 24.7-57.2 61 0 36.5 25.9 60.9 57.2 60.9 30.6 0 56.5-24.4 56.5-61m-189.2 0c0-74 59.7-128.7 132.7-128.7 72.3 0 132 54.8 132 128.8 0 74.2-59.7 129-132 129-73 0-132.7-54.8-132.7-129M481.5 54.4V126h139c.7 0 .9.5.6 1L481.4 364.5c-3.4 6.2-4.2 7.5-4.2 13.6v14h271v-71.6H597.7a.6.6 0 0 1-.5-1L738.5 79c1.1-2.3 2.3-4 2.3-9.6v-15zM798.4 148.6H877V392h-78.7zm39.3-122c24.6 0 44.8 19.8 44.8 44.2S862.3 115 837.7 115 793 95.2 793 70.8s20.3-44.2 44.8-44.2 "></path>
                        <path d="M242.2 106.3c1.8-.4 2.6.2 3.7 1.4 6.1 7 26 31.1 31.4 37.7a2.1 2.1 0 0 1-.5 3.2 714.8 714.8 0 0 0-109.3 107c-.5.6-.1.7.2.5 43.3-18.6 144.7-48.2 190.5-56.6v-57.9L179.2.2.2 141.6V205c55.5-33 183.9-84.1 242-98.6z"></path>
                        <path d="M96.6 349.5a3 3 0 0 1-3.8-.6l-33.5-39.8c-.9-1-1-1.6.2-3.4C85.3 268 138 209 171.5 184.1c.7-.5.5-1-.2-.7C136.3 195 37 237.6 0 258.4v135.2h358.2V263.5c-49 8.3-195.1 51.8-261.6 86z"></path>
                        <path d="M1895.2 113.9c0-7-4.9-12-11.7-12H1871v34h5.6v-10.1h5.5l6.2 10.2h6.4l-6.7-11c4.3-1.7 7.2-5.8 7.2-11.1zm-12 6.9h-6.6V107h6.6c3.7 0 6.4 3 6.4 7s-2.7 6.8-6.4 6.8z"></path>
                        <path d="M1881.8 90.2a29.4 29.4 0 1 0 29.4 29.3 29.4 29.4 0 0 0-29.4-29.3zm0 53a23.7 23.7 0 1 1 23.8-23.7 23.8 23.8 0 0 1-23.8 23.8z"></path>
                    </g>
                </svg>
                <a href="http://www.zillow.com/profile/Gabali-Realty/"> See more reviews </a>
            </div>
            <div class="review-text" *ngIf="zillowAPICalled | async" >
                <span> {{ zillowReviews[5]["reviewDate"] }} - {{ zillowReviews[5]["reviewer"] }} </span>
                <span> {{ zillowReviews[5]["reviewSummary"] }} </span>
                <div class="description">
                    <p> {{ zillowReviews[5]["description"] }} </p>
                    <a [href]="zillowReviews[5]['reviewURL']"> Read more </a>
                </div>
            </div>
        </div> 
        </div>
    </div>
</div>

