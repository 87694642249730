import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from  'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment'
import { BehaviorSubject } from "rxjs";
import { ToastService } from 'angular-toastify';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  // Data memebers
  public isLoggedIn = new BehaviorSubject<Boolean>(false);
  public isAdmin = false;
  public userEmail = null;
  public userID = null;
  public favoriteListings = [];
  // Methods
  constructor(private router: Router, public afAuth: AngularFireAuth, private http: HttpClient,private _toastService: ToastService) { 

    this.afAuth.authState.subscribe(user => {
      if(user) {
        localStorage.setItem('user', JSON.stringify(user));
        this.isLoggedIn.next(true);
        this.userEmail = this.getUserEmail();
        if(this.userEmail==="gabalidevelopment@gmail.com"||this.userEmail==="gabalirealty@gmail.com") {
          this.isAdmin = true;
        }
        else {
          this.isAdmin = false;
        }
        this.userID = user.uid;
        this.http.get(environment.API_URL + 'favorites/' + this.userEmail).subscribe( (favorites: Object[]) => { this.favoriteListings = favorites; })
      }
      else {
        localStorage.setItem('user', null);
        this.isLoggedIn.next(false); 
        this.favoriteListings = []
      }
    });
  }
  checkAuthenticated() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  async login(username: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(username, password);
    this._toastService.success('You are logged in');
  }

  getUserData() { 
    return JSON.parse(localStorage.getItem('user'));  
  }

  async forgotPassword(username: string) {
    return await this.afAuth.sendPasswordResetEmail(username);
  }

  async logout(redirect: string) {
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    this.isLoggedIn.next(false)
    this._toastService.error('You are logged out');
  }

  getUserEmail() { 
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return user['email']; 
    }
  }
  uploadImage() {
    //TODO code for uploading image file to firebase storage
    var prom = this.afAuth.currentUser;
    prom.then(user => {
      user.updateProfile({
        photoURL: 'firebase storage url'//use firebase url
      })
    })
  }

  async registerUser(profile: Object, password: string) {
    const result = await this.afAuth.createUserWithEmailAndPassword(profile['email'], password)
    await (await this.afAuth.currentUser).sendEmailVerification();
    profile["id"] = (await this.afAuth.currentUser).uid
    this.http.post(environment.API_URL + "register/", JSON.stringify(profile)).subscribe(res => { })
  }

  async loginWithGoogle() {
    let response;
    try {
      await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider()).then(resp => response = resp)
      let userProfile = {
        name: response.user.displayName,
        email: response.user.email,
        phone: response.user.phoneNumber,
        id: response.user.uid,
        agent: "No Agent Yet"
      }
      this.http.post(environment.API_URL + "register/", JSON.stringify(userProfile)).subscribe(res => { })
      if(this.userEmail==="gabalidevelopment@gmail.com"||this.userEmail==="gabalirealty@gmail.com") {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
      }
      this._toastService.success('You are logged in');
    }
    catch(err) {
      console.error(err)
      return err;
    }
    return true;
  }
  async loginWithFacebook() {
    let response;
    try {
      await this.afAuth.signInWithPopup(new auth.FacebookAuthProvider()).then(resp => response = resp)
      let userProfile = {
        name: response.user.displayName,
        email: response.user.email,
        phone: response.user.phoneNumber,
        id: response.user.uid,
        agent: "No Agent Yet"
      }
      if(this.userEmail==="gabalidevelopment@gmail.com"||this.userEmail==="gabalirealty@gmail.com") {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
      }
      this._toastService.success('You are logged in');
    }
    catch(err) {
      return err;
    }
    return true;
  }
  postFavorite() {
    const payload = {
      "email": this.userEmail,
      "favorites": this.favoriteListings
    }
    this.http.post(environment.API_URL + "favorites/", JSON.stringify(payload)).subscribe(response => {
    });
  }
}
