import { Component, OnInit,ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ApiSentMessageSuccessModalComponent as successModal} from '../api-sent-message-success-modal/api-sent-message-success-modal.component';
@Component({
  selector: 'app-home-estimate-page',
  templateUrl: './home-estimate-page.component.html',
  styleUrls: ['./home-estimate-page.component.css']
})
export class HomeEstimatePageComponent implements OnInit {
  formattedAddress = ""
  laredoBounds= new google.maps.LatLngBounds(
    new google.maps.LatLng(27.375255, -99.605297),
    new google.maps.LatLng(27.703629, -99.371649)
  );
  options = {
    componentRestrictions : {
      country: 'US'
    },
/*     bounds: this.laredoBounds,
    strictBounds: true */
  }
  constructor(private router: Router,  public dialog: MatDialog) { }
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  ngOnInit(): void {
  }
  openZestimate() {
    if(this.formattedAddress) {
      const encodedAddress = this.formattedAddress.slice(0,-5).split(',').join('').toLowerCase().split(' ').join('+')
      this.router.navigate(["/zestimate"],{ queryParams: {address: encodedAddress} })//remove ', USA' at end
    }

  }
  public handleAddressChange(address: any) {
    this.formattedAddress = address.formatted_address;
    console.log(this.formattedAddress);
  }
  openSuccessModal() {
    const dialogRef = this.dialog.open(successModal,{ width: '250px' });
  }
}
