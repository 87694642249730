import { Routes } from '@angular/router';

import { HomeComponent } from '../home/home.component'; 
import { BuyerGuideComponent } from '../buyer-guide/buyer-guide.component';
import { SellerGuideComponent } from '../seller-guide/seller-guide.component';
import { ContactPageComponent } from '../contact-page/contact-page.component';
import { HomeEstimatePageComponent } from '../home-estimate-page/home-estimate-page.component';
import { AgentsComponent } from '../agents/agents.component';
import { FeaturedPropertiesComponent } from '../featured-properties/featured-properties.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { AccountComponent } from '../account/account.component';
import { ZestimateComponent } from '../zestimate/zestimate.component';
import { FavoritePropertiesComponent } from '../favorite-properties/favorite-properties.component';
import { ModalResidentialComponent } from '../modal-residential/modal-residential.component';
import { AdminPageComponent } from '../admin-page/admin-page.component';
import { AdminAuthGuardService } from '../services/admin-auth-guard.service';
import { CanActivateChildGuard } from "../services/can-activate-child-guard.service";


export const routes: Routes = [
    {path: '', canActivateChild: [ CanActivateChildGuard ], children: [
        {path: 'home', component: HomeComponent },
        {path: 'buyer-guide', component: BuyerGuideComponent },
        {path : '', redirectTo: 'home', pathMatch: 'full'},
        {path: 'seller-guide', component: SellerGuideComponent },
        {path: 'contact-page', component: ContactPageComponent },
        {path: 'home-estimate-page', component: HomeEstimatePageComponent },
        {path: 'agents', component: AgentsComponent},
        {path: 'admin',component: AdminPageComponent, canActivate: [ AdminAuthGuardService, ]},
        {
            path: 'featured-properties',
            component: FeaturedPropertiesComponent,
            children: [
                {path: 'featured-properties/popup', component: ModalResidentialComponent, outlet: 'modal'},
            ]
        },
        {path: 'favorite-properties', component: FavoritePropertiesComponent, canActivate: [ AuthGuardService ] },
        {path: 'account', component: AccountComponent, canActivate: [ AuthGuardService ]},
        {path: 'zestimate', component: ZestimateComponent},
    ]}
    
];