import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, Validator, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  @Output() switchModalSignIn = new EventEmitter<string>();
  @Output() signupCloseModal = new EventEmitter<string>();
  
   //Form controls
   passwordFormHide = true;
   invalidSignupAttempt = false;
   otherError = false;
   errorMessage = "";
   
   signupForm = new FormGroup({
     email: new FormControl('', [Validators.email, Validators.required]),
     password: new FormControl('', [Validators.required, Validators.pattern('^(?:(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|/]))|(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|/]))|(?:(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|/]))).{8,32}$')]),
     confirmPassword: new FormControl('', [Validators.required]),
     firstName: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z]*')]),
     lastName: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z]*')]),
     phone: new FormControl('',[Validators.required,Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$")]),
     agent: new FormControl('',Validators.required)
   });
   agents = ["No Agent Yet"]

  constructor(private authService: AuthService, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get(environment.API_URL + "agents")
    .subscribe(response => {
      const res = response as JSON;
      const founder = res["founder"]
      const agentsList: Array<Object> = res["agents"];
      this.agents.push(founder["name"])
      agentsList.forEach(agent => {
        this.agents.push(agent["name"])
      });
    });
  }
  async submitForm() {
    if (this.signupForm.valid) {
      const email = this.signupForm.get("email").value;
      const password = this.signupForm.get("password").value;
      const firstName = this.signupForm.get("firstName").value;
      const lastName = this.signupForm.get("lastName").value;
      const phone = this.signupForm.get("phone").value;
      const agent = this.signupForm.get("agent").value;
      console.log(agent)
      let profile = {
        "name": firstName + ' ' + lastName,
        "email": email,
        "phone": phone,
        "agent": agent
      }

      try {
        this.authService.registerUser(profile, password);
        this.signupCloseModal.emit();
      }
      catch(err) {
        console.error(err)
        if(err.code === "auth/email-already-in-use") {
          this.invalidSignupAttempt = true;
          this.errorMessage = "Email is already registered to account"
        }
        else {
          this.errorMessage = err.message;
        }
      }
    }
  }
  openSignIn() {
    this.switchModalSignIn.emit("yeet");
  }
  getErrorMessage() {
    return this.errorMessage;
  }

}

