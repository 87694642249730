import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthApiError } from '@okta/okta-auth-js';
import { AuthService } from '../services/auth.service';
import { Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ForgotPasswordComponent} from './forgot.password.component'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // input message 
  @Input() message?: String;
  @Output() switchModalSignUp = new EventEmitter<string>();
  @Output() loginCloseModal = new EventEmitter<string>();

  //Form controls
  passwordFormHide = true;
  validLoginAttempt = true;
  validUserAttempt = true;
  returnUrl = "/home";
  errorMessage = "";

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', Validators.required)
  });

  constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog ) { }

  async ngOnInit() {

  }
  async loginFacebook() {
    let response = await this.authService.loginWithFacebook();
    if(response===true)
      this.loginCloseModal.emit()
    if(response.code === "auth/account-exists-with-different-credential") {
      this.validLoginAttempt = false;
      this.errorMessage = "This email is registered to an account with a different provider. Try logging in with the same email with another method."
    }
  }
 async loginGoogle() {
  let response = await this.authService.loginWithGoogle();
  if(response===true) {
    this.loginCloseModal.emit()
  }
  if(response.code === "auth/account-exists-with-different-credential") {
    this.validLoginAttempt = false;
    this.errorMessage = "This email is registered to an account with a different provider. Try logging in with the same email with another method."
  }
}
 getErrorMessage() {
   return this.errorMessage;
 }
  async onSubmit() {
    if (this.loginForm.valid) {
      try {
        const username = this.loginForm.get("email").value;
        const password = this.loginForm.get("password").value;
        await this.authService.login(username, password);
        this.validLoginAttempt = true;
        this.loginCloseModal.emit();
      } catch (err) {
        if(err.code === "auth/user-not-found") {
          this.validUserAttempt = false;
          this.errorMessage = "Email is not registered"
        }
        else if (err.code ==="auth/wrong-password") {
          this.validLoginAttempt = false;
          this.errorMessage = "Incorrect password for matched email"
        }
        else if(err.code === "auth/account-exists-with-different-credential") {
          this.validLoginAttempt = false;
          this.errorMessage = "This email is registered to an account with a different provider. Try logging in with the same email with another method."
        }
      }
    }
    else {
      console.error("How did the user submit without the submit button?");
    }
  }
  openSignUp() {
    this.switchModalSignUp.emit("yeet");
  }
  openForgotPassword() {
    var modalRef = this.dialog.open(ForgotPasswordComponent)
    const subscribeDialog = modalRef.componentInstance.forgotPasswordCloseModal.subscribe((data) => {
      this.dialog.closeAll();
    });
  }
}