<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<mat-card *ngIf="mapCard !== 'true'">
    <div class="img-container" (click)="openModal()">
        <img [src]="property['ImagePaths'][currentIndex]"
        mat-card-image  class="image-slide" loading="lazy">
        <div class="image-shadow"></div>
    </div>

    <svg *ngIf="left_arrow_display" class="leftarrow" id="leftarrow-{{property['ListingID']}}"
    (click)="prevPicture()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"
    width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>
    <svg *ngIf="right_arrow_display" class="rightarrow" id="rightarrow-{{property['ListingID']}}" (click)="nextPicture()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
    <button *ngIf="this.hasFavorite" class="likebutton" mat-icon-button color="warn" (click)=" toggleFavorite();">
        <mat-icon color="warn"> {{ property["icon_label"] }} </mat-icon>
    </button>
    <h2 *ngIf="isResidentialProperty()" class="address-residential">{{ property["Address"]["Number"] }} {{ property["Address"]["Street"] }}</h2>
    <h2 *ngIf="!isResidentialProperty()" class="address-other">{{ property["Address"]["Number"] }} {{ property["Address"]["Street"] }}</h2>
    <h3>{{ property["Address"]["Subdivision"] }}</h3>
    <mat-card-content (click)="openModal()">
        <div class="property-info" >
            <div class="property-line">
                <span class="bold">{{ property["Price"] | currency: 'USD' : 'symbol' : '1.0-0' }}</span>
            </div>
            <div class="property-line" *ngIf="isResidentialProperty()"> 
                <div><span class="bold">{{ property["FullBaths"] }}</span><span>Baths </span></div>
                <div><span class="bold">{{ property["Bedrooms"] }} </span><span>Beds </span></div>
                <div><span class="bold">{{ property["Area"] }} </span><span>SqFt </span></div>
            </div>
            <div class="property-line" *ngIf="isLandProperty()"> 
                <div> <span class="bold">Area </span><span>{{ property["Area"] }} Acre</span></div>
                <!-- <div><span class="bold">Zoning</span><span>B4</span></div> -->
            </div>
            <div class="property-line" *ngIf="isCommercialProperty()"> 
                <div><span class="bold">Size </span><span>{{ property["Area"]}} Sq. Ft.</span></div>
            </div>
            <div class="property-line" *ngIf="isIndustrialProperty()"> 
                <div><span class="bold">Size </span><span>{{ property["Area"]}} Sq. Ft.</span></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>