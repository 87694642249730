<h1>Account Settings</h1>
<div class="separator-horizontal"></div>

<div class="login-information">
    <h3>Login information</h3>
    <div class="info">
        <div class="email">
            <b>Login email</b>
            <p> {{ email }} </p>
            <i></i>
        </div>

        <div class="password">
            <b>Change password</b>
            <i (click) = "changePassword()"></i>
            <!--  -->
        </div>
    </div>
</div>

<div class="profile-information">
    <h3>Profile information</h3>
    <div class="info">
        <div class="name">
            <b>Name</b>
            <p>{{ displayName }} </p>
            <i></i>
        </div>

        <div class="phone">
            <b>Phone</b>
            <p> ({{mobile| slice:0:3}}) {{ mobile | slice:3:6}}-{{ mobile | slice:6:10 }}</p>
        </div>
    </div>
</div>

<button (click)="logout()">Logout</button>