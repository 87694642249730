
<div class="carousel-nav">
    <button class="carousel-button slide-selected-button" id="1" (click)="changeSlide(1)">1</button>
    <button class="carousel-button" id = "2" (click)="changeSlide(2)">2</button>
    <button class="carousel-button" id = "3" (click)="changeSlide(3)">3</button>
    <button class="carousel-button" id = "4" (click)="changeSlide(4)">4</button>
    <button class="carousel-button" id = "5" (click)="changeSlide(5)">5</button>
</div>

<div class="carousel-body">
    <div class="carousel-slide selected-slide" id="slide-1">
        <img src="../../assets/stockPhotos/AgentsImage.jpeg">
        <div class="slide-text">
            <span class="step">
                Pick a Gabali Realty Agent
            </span>
            <p class="description">
                Navigating the subtleties of any real estate market can be daunting. Choosing the right agent will help you figure out what your home is worth in relation to
                others around it. Using a panoptic approach in studying market trends and diligently striving to innovate marketing strategies, a Gabali Realty
                agent can make the entire process from staging to selling your home as easy as possible.            </p>
        </div>
    </div>
    <div class="carousel-slide" id="slide-2">
        <img src="../../assets/stockPhotos/GetYourHomeReady.jpg">
        <div class="slide-text">
            <span class="step">
                Get Your Home Ready
            </span>
            <p class="description">
                Is your house readily marketable? Should you paint and de-clutter? These are questions that can be answered easily and simply by any one of our knowledgeable agents.
                Before you list, a Gabali Realty will consult and advise you on what can be done to add value and avoid a prolonged
                stint on the market.            </p>
        </div>
    </div><div class="carousel-slide" id="slide-3">
        <img src="../../assets/stockPhotos/FindOutHowMuchYourHomeIsWorth.jpg">
        <div class="slide-text">
            <span class="step">
                Find Out How Much Your Home Is Worth
            </span>
            <p class="description">
                While online estimates of property value can be exciting to ponder, a Gabali Realty agent will give you an 'ear to the ground' approach as to what the current market
                indicates and will provide advice on preparing your home in order to maximize value according to your specific needs.            </p>
        </div>
    </div><div class="carousel-slide " id="slide-4">
        <img src="../../assets/stockPhotos/Accept the Offer.jpg">
        <div class="slide-text">
            <span class="step">
                Accept The Offer
            </span>
            <p class="description">
                Accept the offer
                Once listed, a Gabali Realty agent will coordinate showings, negotiations, and eventual offers. While offers may differ
                from the asking price, your agent will help you to either reject, counter, or accept the offer on the table.             </p>
        </div>
    </div>
    <div class="carousel-slide " id="slide-5">
        <img src="../../assets/stockPhotos/CloseOnYourHome.jpg">
        <div class="slide-text">
            <span class="step">
                Close on Your Home
            </span>
            <p class="description">
                Now that you are under contract after accepting the offer, the escrow process will take place until the buyer is ready to close on your home. Your Gabali Realty
                agent will guide you through it ensuring that every deadline is met to ensure timely closing. Its official --- you're ready to sell
                your home! You'll sit down and sign the paperwork to transfer ownership to the buyer. Congrats! You've just sold your home! All the patience and effort
                you've invested has rewarded you with the opportunity of beginning your next adventure!            </p>
        </div>
    </div>
</div>