<div class="container">
    <button class="map-toggle" (click)="toggleMapDisplay()"> {{ buttonText }} </button>
    <div class="left-side" *ngIf="isListDisplay()">
        <div class="loading-cards" *ngIf="!(favoritePropertiesAPICalled | async )">
            <div id="loading-header">
                <div class="header"></div>
                <div class="card-collection">
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    <div class="loading-card">
                        <div class="img-loading"></div>
                        <div class="field-loading"></div>
                        <div class="field-loading"></div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="loaded-cards">
            <div id="featured-header" *ngIf="(favoritePropertiesAPICalled | async )">
                <div class="header"><span class="header-text">Favorite Properties</span></div>
                <div class="empty-property-search" *ngIf="!(favoriteProperties.length>0)" >
                    <img src="../../assets/no_results.png">
                </div>
                <div class="card-collection">
                    <app-residential-card  *ngFor="let property of favoriteProperties | paginate: { id: 'p1', itemsPerPage: 12, currentPage: p1,totalItems: favoriteProperties.length }" hasFavorite="true" [propertyType]="property['Type']" [property]="property" class="card"></app-residential-card>
                </div>
                <div class="flex-center"><pagination-controls (pageChange)="p1 = favoritePageChange($event)" id="p1" responsive="responsive" autoHide="true"></pagination-controls></div>
            </div>
        </div>
    </div>
    <div class="map" *ngIf="isMapDisplay()">
        <agm-map #gm [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapClick)="clickedMap()">
            <agm-marker *ngFor="let property of favoriteProperties" 
            [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
            [iconUrl]="mapIcon" animation="DROP" openInfoWindow="true"
            (markerClick)="selectMarker(infoWindow)" [label]="{color: 'white', text: '$' + property['Price_Suffix']}"
            (mouseOver)="hoverMarker(infoWindow)" (mouseOut)="hoverMarkerExit(infoWindow)">

                <agm-info-window maxWidth="324" (infoWindowClose)="markerClicked=false" class="infoWindow" #infoWindow>
                    <app-residential-card [property]="property" [hasFavorite]="false" [propertyType]="property['Type']" class="card"></app-residential-card>
                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>
</div>