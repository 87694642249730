<div class="hero-photo">
    <div class="body">
        <h1 class="bold">
            What's my home worth?
        </h1>
        <hr>
        <div class="search-bar">
            <input ngx-google-places-autocomplete [options]="options" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
            <button (click)="openZestimate()" mat-flat-button color="accent">Get Estimate</button>
        </div>

    </div>
</div>
<div class="text-background-container">
    <div class="center-text">
        <h2>How do we estimate your home?</h2>
        <p>
            Enter you address to receive a quick estimate of your property. The estimate
            is calculated using trusted real estate sources. Remember that this is only 
            an estimate and that contacting an agent is the best way to get an accurate estimate.
        </p>
    </div>
</div>
<div>
    <div class="center-text">
        <h3>Frequently Asked Questions</h3>
        <h4>Will I make a profit if I sell now?</h4>
        <p>
            This all depends on how much you currently owe on your mortgage, taxes and other  encumbrances that may be
             attached to your property. An expert Gabali Realty agent will help you figure this out.
        </p>
        <h4>How much will it cost me to sell?</h4>
        <p>
            Speaking to a Gabali Realty agent will help shed some light on what fees will be assessed and pertain to your particular property needs.
             Some sellers need to relocate and must sell ASAP; others may want to net a specific amount at the end of the sale. Reaching out to us will help identify your specific real estate needs.. 
        </p>
        <h4>What improvements can I make to my home to add value?</h4>
        <p>
            While making improvements can add value to a property, in some cases, it can require a significant amount
             of monetary investment which some sellers may not be in the position to make at the time. Your Gabali Realty 
             agent will help assess your needs and guide you in the right direction before making possibly any unnecessary expenditures on your property.
        </p>
    </div>
</div>