import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { environment } from 'src/environments/environment';
import { ApiSentMessageSuccessModalComponent as successModal} from '../api-sent-message-success-modal/api-sent-message-success-modal.component';


@Component({
  selector: 'app-tour-modal',
  templateUrl: './tour-modal.component.html',
  styleUrls: ['./tour-modal.component.css']
})
export class TourModalComponent implements OnInit {
  
  agents = ["No Agent Yet"]

  contactForm = new FormGroup({
    name: new FormControl('',Validators.required),
    email: new FormControl('',[Validators.required, Validators.email]),
    phone: new FormControl('',[Validators.required,Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$")]),
    agent: new FormControl('',Validators.required),
    reqDate: new FormControl('',Validators.required)
  });
  failedSubmit = false; 

  constructor(public dialog: MatDialog, private http: HttpClient,private _toastService: ToastService, private dialogRef: MatDialogRef<TourModalComponent>) { }
  ngOnInit(): void {
    this.http.get(environment.API_URL + "agents")
    .subscribe(response => {
      const res = response as JSON;
      const objects: Array<Object> = res["agents"];
      objects.forEach(element => {
        this.agents.push(element["name"])
      });
    });
  }

  async submitForm(formData: any, formDirective: FormGroupDirective) {
    var dateTimeStamp = new Date();
    let year = dateTimeStamp.getFullYear();
    this.contactForm.addControl('year', new FormControl(year));
    let day = dateTimeStamp.getDate();
    this.contactForm.addControl('day', new FormControl(day));
    this.contactForm.addControl('month', new FormControl(dateTimeStamp.getMonth()+1));
    this.contactForm.addControl('time',new FormControl(dateTimeStamp.getHours()+':'+dateTimeStamp.getMinutes()));

    const response = await this.http.post(environment.API_URL + "tour-form", JSON.stringify(this.contactForm.value)).toPromise();
    console.log(response);
    if (response != 201) {
      this.failedSubmit = true;
      this.contactForm.reset();
      formDirective.resetForm();
    }
    else {
      this._toastService.success('An agent will reach out soon');
      this.dialogRef.close()
    }
  }
}
