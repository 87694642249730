<div class="login-modal">
    <span [mat-dialog-close] class="cross">&times;</span>
    <div *ngIf="message"> {{ message }} </div>

    <div class="intro">
        <h2>Login to your account</h2>
        <p>Save your favorite properties and improve your home searching experience</p>
        <p>Don't have an account? <a class="cursor-pointer"(click)="openSignUp()">Sign up</a></p>
    </div>
<!--     <div class="login-buttons">
        <button (click)="loginFacebook()" class="facebook-button"><img style="width: 32px; height: 32px;" src="../../assets/icons/fb-logo.png"><span>Login With Facebook</span></button>
        <button (click)="loginGoogle()" class="google-button"><img style="width: 32px; height: 32px;" src="../../assets/icons/google-icon.svg"><span>Login With Google</span></button>
    </div> -->

    <div class="separator">Login with Email</div>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>Email</mat-label>
            <input matInput formControlName = "email" required>
            <mat-error *ngIf="loginForm.controls['email'].hasError('required')">Enter your email </mat-error>
            <mat-error *ngIf="loginForm.controls['email'].hasError('email')">This does not seem to be a valid email</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>Password</mat-label>
            <input matInput [type]="passwordFormHide ? 'password' : 'text'" formControlName = "password" required>
            <button mat-icon-button matSuffix (click)="passwordFormHide = !passwordFormHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordFormHide">
                <mat-icon>{{passwordFormHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="loginForm.controls['password'].hasError('required')">Enter your password </mat-error>
        </mat-form-field>

        <a class="forgot-password" (click)="openForgotPassword()">Forgot password?</a>
        <span class="invalid-login" *ngIf="!validLoginAttempt || !validUserAttempt">{{getErrorMessage()}}</span>
        <button class="login-btn" type="submit" mat-flat-button color="accent" [disabled]="!loginForm.valid">Login</button>
        
    </form>
</div>
