// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL:  "https://gabali-backend.herokuapp.com/api/",
  ZWS_ID: "X1-ZWz171pqnshzbf_3v2ae",
  MAPS_KEY: "AIzaSyDxoqxojI6W8GY3TNQc5esPIW4TD6gTvp8",
  firebaseConfig: {
      apiKey: "AIzaSyDZwBtquJ7EfCkWfOJM0eZIebmRBIEMNXI",
      authDomain: "gabali-realty.firebaseapp.com",
      databaseURL: "https://gabali-realty.firebaseio.com",
      projectId: "gabali-realty",
      storageBucket: "gabali-realty.appspot.com",
      messagingSenderId: "332676127797",
      appId: "1:332676127797:web:3763d64cecdb3a127b257c"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
