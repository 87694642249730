<header [class]="changeHeaderClass()">
    <img src="../../assets/gabali logo.png" routerLink="/home" alt="Gabali Realty logo">
    <ul class="navbar-large">
        <li class="navbar-item">
            <div  class="dropdown">
                <span class="dropdown-button">
                    My Profile
                    <span class="down-arrow">
                        <i class="fa fa-sort-down"></i>
                    </span>
                </span>
                <div *ngIf="isLoggedIn" class="dropdown-content">
                    <a routerLink="/favorite-properties">Favorite List</a>
                    <a *ngIf="this.auth.isAdmin" routerLink="/admin" routerLinkActive="active">Admin Login</a>
                    <a (click)="logout()">Log Out</a>
                </div>
                <div *ngIf="!isLoggedIn" class="dropdown-content">
                    <a (click)="openSignIn()">Sign In</a>
                    <a (click)="openSignUp()">Sign Up</a>
                </div>
            </div>
        </li>
        <li class="navbar-item">
            <a routerLink="/featured-properties" routerLinkActive="active">
                Featured Properties
            </a>
        </li>
        <li class="navbar-item">
            <div class="dropdown">
                <span class="dropdown-button">
                    Buy
                    <span class="down-arrow">
                        <i class="fa fa-sort-down"></i>
                    </span>
                </span>
                <div class="dropdown-content">
                    <a routerLink="/featured-properties">Real Estate Search</a>
                    <a routerLink="/buyer-guide" routerLinkActive="active">Buyer's Guide</a>
                </div>
            </div>
        </li>
        <li class="navbar-item">
            <div class="dropdown">
                <span class="dropdown-button">
                    Sell
                    <span class="down-arrow">
                        <i class="fa fa-sort-down"></i>
                    </span>
                </span>
                <div class="dropdown-content">
                    <a routerLink="/home-estimate-page" routerLinkActive="active">What's My Home Worth?</a>
                    <a routerLink="/seller-guide" routerLinkActive="active">Seller's Guide</a>
                </div>
            </div>
        </li>   
        <li class="navbar-item">
            <a routerLink = "/agents" routerLinkActive="active">
                Agents
            </a>
        </li>
        <li class="navbar-item">
            <a routerLink="/contact-page" routerLinkActive="active">
                Contact Us
            </a>
        </li>
    </ul>
</header>
<mat-drawer-container [class]="changeHeaderClassMobile()" [hasBackdrop]="true">
    <mat-drawer style="z-index: 100;" class="sidenav" #drawer mode="over" position="end">
        <div class="nav-exit"> <span>Close </span> <i class="fa fa-close fa-2x" id="close-sidebar" (click)="drawer.close()"></i> </div>
        <div class="sidenav-drop-container" (click)="toggleAccountMenu()" >
            My Profile
            <span class="down-arrow-side" id="account-down-arrow">
                <i class="fa fa-sort-down fa-lg"></i>
            </span>
    
            <span class="up-arrow-side" id="account-up-arrow">
                <i class="fa fa-sort-up fa-lg"></i>
            </span>
        </div>
        <div class="sidenav-dropdown" *ngIf="isLoggedIn" id="sidenav-account-logged-in-drpdwn">
            <a (click)="drawer.close()" routerLink="/favorite-properties">Favorite List</a>
            <a *ngIf="this.auth.isAdmin" (click)="drawer.close()" routerLink="/admin" routerLinkActive="active">Admin Login</a>
            <a (click)="logout();drawer.close()">Log Out</a>
        </div>
        <div class="sidenav-dropdown" *ngIf="!isLoggedIn" id="sidenav-account-logged-out-drpdwn">
            <a (click)="openSignIn();drawer.close()">Sign In</a>
            <a (click)="openSignUp();drawer.close()">Sign Up</a>
        </div>
        <div class="sidenav-tab">
            <a (click)="drawer.close()" routerLink="/featured-properties">
                Featured Properties
            </a>
        </div>
        <div class="sidenav-drop-container" (click)="toggleBuyMenu()">
            Buy
            <span class="down-arrow-side" id="buy-down-arrow">
                <i class="fa fa-sort-down fa-lg"></i>
            </span>
    
            <span class="up-arrow-side" id="buy-up-arrow">
                <i class="fa fa-sort-up fa-lg"></i>
            </span>
        </div>
        <div class="sidenav-dropdown" id="sidenav-buy-drpdwn">
            <a (click)="drawer.close()" routerLink="/featured-properties">Real Estate Search</a>
            <a (click)="drawer.close()" routerLink="/buyer-guide" routerLinkActive="active">Buyer's Guide</a>
        </div>
        <div class="sidenav-drop-container" (click)="toggleSellMenu()">
            Sell
            <span class="down-arrow-side" id="sell-down-arrow">
                <i class="fa fa-sort-down fa-lg"></i>
            </span>
    
            <span class="up-arrow-side" id="sell-up-arrow">
                <i class="fa fa-sort-up fa-lg"></i>
            </span>
        </div>
        <div class="sidenav-dropdown" id="sidenav-sell-drpdwn">
            <a (click)="drawer.close()" routerLink="/home-estimate-page" >What's My Home Worth?</a>
            <a (click)="drawer.close()" routerLink="/seller-guide" >Seller's Guide</a>
        </div>
        <div class="sidenav-tab">
            <a (click)="drawer.close()" routerLink="/agents">
                Agents
            </a>
        </div>
        <div class="sidenav-tab">
            <a (click)="drawer.close()"routerLink="/contact-page" >
                Contact Us
            </a>
        </div>

    </mat-drawer>
    <mat-drawer-content>
        <ul class="icons">

            <img id="mobile-logo" src="../../assets/gabali logo.png" routerLink="/home" alt="Gabali Realty logo">
    
            <li class="icon" id="menu-icon">
                <i class="fa fa-navicon fa-2x" (click)="drawer.toggle();"></i>
            </li>
        </ul>
    </mat-drawer-content>
</mat-drawer-container>
<lib-toastify-toast-container [hideProgressBar]="true" [autoClose]="2500" [position]="'top-center'"></lib-toastify-toast-container>