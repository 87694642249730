import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoginPopupService } from '../services/login-popup.service';
import { MatDialog,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalResidentialComponent } from '../modal-residential/modal-residential.component';
@Component({
  selector: 'app-residential-card',
  templateUrl: './residential-card.component.html',
  styleUrls: ['./residential-card.component.css']
})

export class ResidentialCardComponent implements OnInit {

  currentIndex=0;
  lastPicIndex: number;
  left_arrow_display: boolean = false;
  right_arrow_display: boolean = true;
  modal_display = false;
  icon_label = "favorite_outline";
  isFavorite: boolean;

  @Input() propertyType?: string;
  @Input() property: Object;
  @Input() hasFavorite: boolean;
  @Input() clickable = "true";
  @Input() mapCard = "false";
  @Input() sharedProperty = "";

  constructor(private authService: AuthService, public loginPopup: LoginPopupService, private router: Router, public dialog: MatDialog) {
  }
  ngOnInit(): void {
    this.lastPicIndex=this.property["ImagePaths"].length-1;
    this.property["icon_label"] = this.property["favorite"] ? "favorite" : "favorite_outline";
    this.isFavorite = this.property["favorite"];
    if(this.sharedProperty === this.property['ListingID']) {
      this.openModal()
    }
  }
  ngOnDestroy() {
    
  }
  async toggleFavorite() {
    if (!this.authService.checkAuthenticated()) {
      this.loginPopup.subject.next('yeet');
    }
    else {
      this.property["favorite"] = !this.property["favorite"]
      this.property["icon_label"] = this.property["favorite"] ? 'favorite' : 'favorite_outline';
      if(this.property["favorite"]) {
        this.authService.favoriteListings.push(this.property["ListingID"])
      }
      else {
        this.authService.favoriteListings = this.authService.favoriteListings.filter(fave => fave !== this.property["ListingID"])
      }
      this.authService.postFavorite()
    }
  }

  isResidentialProperty() { return this.propertyType.toLowerCase()==="residential"; }
  isCommercialProperty() { return this.propertyType.toLowerCase()==="commercial"; }
  isIndustrialProperty() { return this.propertyType.toLowerCase()==="industrial"; }
  isLandProperty() { return this.propertyType.toLowerCase()==="land"; }


  nextPicture(): void {
    this.currentIndex += 1;
    this.updateArrowVisibility();
  }
  prevPicture(): void {
    this.currentIndex -= 1;
    this.updateArrowVisibility();
  }
  updateArrowVisibility(): void {
    if(this.currentIndex===0) {
      this.left_arrow_display = false;
      this.right_arrow_display = true;
    }
    else if(this.currentIndex===this.lastPicIndex) {
      this.left_arrow_display = true;
      this.right_arrow_display = false;
    }
    else {
      this.left_arrow_display = true;
      this.right_arrow_display = true;
    }
  }
  openModal() {
    const dialogRef = this.dialog.open(ModalResidentialComponent, {
      maxHeight: '-webkit-fill-available',
      maxWidth: '100vw',
      panelClass: 'fullscreen-modal',
      data: this.property,
      closeOnNavigation: false
    });
  }
  closeModal() {
    this.dialog.closeAll();
  }
  getListingID() {
    return this.property["ListingID"];
  }
}
