import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';
import { ActivatedRoute } from '@angular/router'
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { TourModalComponent } from '../tour-modal/tour-modal.component';
import { AuthService } from '../services/auth.service';
import { LoginPopupService } from '../services/login-popup.service';
import { ShareModalComponent } from '../share-modal/share-modal.component';
@Component({
  selector: 'app-modal-residential',
  templateUrl: './modal-residential.component.html',
  styleUrls: ['./modal-residential.component.css']
})
export class ModalResidentialComponent implements OnInit {

  @Input() property: object;
  @Input() propertyType: string; 
  zoom = 18;
  markerClicked = false;

  text_label = "Save";
  
  galleryImages: NgxGalleryImage[] = [];
  galleryOptions: NgxGalleryOptions[] = [
    {
        width: '99.5%',
        height: '436px',
        thumbnailsRows: 2,
        thumbnailsColumns: 4,
        thumbnailsMargin: 0,
        thumbnailMargin: 2,
        imageAnimation: NgxGalleryAnimation.Slide,
        image: false
    },
    {
      breakpoint: 1400,
      thumbnailsColumns: 3,
    },
    {
      breakpoint: 900,
      width: '100%',
      thumbnailsColumns: 2,
    },
    // max-width 575
    {
      breakpoint: 575,
      width: '100%',
      height: '218px',
      thumbnailsRows: 1,
      thumbnailsColumns: 1,
      thumbnailsMargin: 0,
      thumbnailMargin: 0
    },
  ];

  // google maps icons
  mapIcon = {
    url: '../../assets/icons/rounded-rectangle.svg',
    scaledSize: {
      width: 200,
      height: 50,
    }
  }

  constructor(private http: HttpClient, 
              private router: ActivatedRoute, 
              @Inject(MAT_DIALOG_DATA) 
              public data,
              public dialog: MatDialog,
              private authService: AuthService,
              public loginPopup: LoginPopupService) {
    this.property = data;
  }
  async ngOnInit() {
    this.propertyType = this.property["Type"];
    for(var i=0;i<this.property["ImagePaths"].length;i++) {
      this.galleryImages.push(
        {
          small: this.property["ImagePaths"][i],
          medium: this.property["ImagePaths"][i],
          big: this.property["ImagePaths"][i],
      });
    }
    //Some logic to deal with bad cases of data
    if(this.property["Pool"]==="") {//empty field possible to be given from backend
      this.property["Pool"] = "N/A";
    }
    this.property["Area"] = this.numberWithCommas(this.property["Area"]);
    this.text_label = this.property["favorite"] ? "Saved" : "Save";
  }

  closeModal() {
    this.dialog.closeAll();
  }

  isResidentialProperty() { return this.propertyType.toLowerCase()==="residential"; }
  isCommercialProperty() { return this.propertyType.toLowerCase()==="commercial"; }
  isIndustrialProperty() { return this.propertyType.toLowerCase()==="industrial"; }
  isLandProperty() { return this.propertyType.toLowerCase()==="land"; }


  async toggleFavorite() {
    if (!this.authService.checkAuthenticated()) {
      this.loginPopup.subject.next('yeet');
    }
    else {
      this.property["favorite"] = !this.property["favorite"];
      this.property["icon_label"] = this.property["favorite"] ? 'favorite' : 'favorite_outline';
      if(this.property["favorite"]) {
        this.authService.favoriteListings.push(this.property["ListingID"])
        this.text_label = "Saved"
      }
      else {
        this.authService.favoriteListings = this.authService.favoriteListings.filter(fave => fave !== this.property["ListingID"])
        this.text_label = "Save"
      }
      this.authService.postFavorite()
    }
  }

  openContactModal() {
    const dialogRef = this.dialog.open(ContactModalComponent, {
      width: 'fit-content',
      height: 'fit-content',
      data: this.property 
    })
  }
  openTourModal() {
    const dialogRef = this.dialog.open(TourModalComponent, {
      width: 'fit-content',
      height: 'fit-content',
      data: this.property 
    })
  }
  openShareModal() {
    const dialogRef = this.dialog.open(ShareModalComponent, {
      width: 'fit-content',
      height: 'fit-content',
      data: this.property
    })
  }
  numberWithCommas(num) {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}


 