import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  email: string;
  displayName: string;
  mobile: string;

  constructor(public authService: AuthService) {  }

  async ngOnInit() {
    const userProfile = this.authService.getUserData();
    if (userProfile) {
      this.email = userProfile["email"];
      console.log(this.email);
      this.displayName = userProfile['displayName'];
      this.mobile = userProfile["phoneNumber"];
    }
    else {
      console.log(userProfile)
    }
  }
  changePassword() {

  }
  logout() {
    this.authService.logout('/home');
  }
}
