
<div class="carousel-nav">
    <button class="carousel-button slide-selected-button" id="1" (click)="changeSlide(1)">1</button>
    <button class="carousel-button" id = "2" (click)="changeSlide(2)">2</button>
    <button class="carousel-button" id = "3" (click)="changeSlide(3)">3</button>
    <button class="carousel-button" id = "4" (click)="changeSlide(4)">4</button>
    <button class="carousel-button" id = "5" (click)="changeSlide(5)">5</button>
</div>

<div class="carousel-body">
    <div class="carousel-slide selected-slide" id="slide-1">
        <img src="../../assets/stockPhotos/AgentsImage.jpeg">
        <div class="slide-text">
            <span class="step">
                Pick a Gabali Realty Agent
            </span>
            <p class="description">
                Choosing the best agent who will guide you and place your best interests first can be done 
                here at Gabali Realty. Working with our trusted professionals will take the stress out of the whole process and ensure your experience is a happy one.
            </p>
        </div>
    </div>
    <div class="carousel-slide" id="slide-2">
        <img src="../../assets/stockPhotos/GetPrequalified.jpg">
        <div class="slide-text">
            <span class="step">
                Get Pre-Qualified
            </span>
            <p class="description">
                While it may seem intimidating to check your credit score & gather income tax returns, the no-obligation pre-qualification process is an important 
                step in establishing your buying power. Choosing the right mortgage lender to get pre-qualified is just as important as choosing the home itself.
            </p>
        </div>
    </div><div class="carousel-slide" id="slide-3">
        <img src="../../assets/stockPhotos/ChooseMortgageLender.jpg">
        <div class="slide-text">
            <span class="step">
                Choose the Right Mortgage Lender
            </span>
            <p class="description">
                Many lending institutions offer a very limited variety of mortgage products. From interest rates to types of financing, choosing the right mortgage lender 
                can ensure that you will have access to the most affordable rates and mortgage programs that are tailored to your financial situation. We highly recommend working with a Mortgage Broker because of their access to a variety of lenders which can guarantee you the best interest rate for your next purchase.
            </p>
        </div>
    </div><div class="carousel-slide " id="slide-4">
        <img src="../../assets/stockPhotos/SearchForHomes.jpg">
        <div class="slide-text">
            <span class="step">
                Begin the Search, View the Homes, Make the Offer.
            </span>
            <p class="description">
                After customizing your search, a Gabali Realty agent will take the time to review all the potential homes you may want to consider. After narrowing down
                 your search, the showings will begin. When you and your agent find the perfect home, an analysis will be conducted to know exactly how much to offer. 
            </p>
        </div>
    </div>
    <div class="carousel-slide " id="slide-5">
        <img src="../../assets/stockPhotos/CloseOnHome.jpg">
        <div class="slide-text">
            <span class="step">
                Close on Your Home
            </span>
            <p class="description">
                Congratulations! You are on the final step to purchase your next home! Once in escrow, your Gabali Realty agent will ensure all contract deadlines are kept, inspections conducted, and closing times are scheduled. Buying a home can be one of the biggest investments you will ever make and you will find at
                 the end of it all, the experience to be exciting, educational, and most of all: EASY.
            </p>
        </div>
    </div>
</div>