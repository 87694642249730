import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.css']
})
export class ContactModalComponent implements OnInit {
  @Input() property: object;

  contactForm = new FormGroup({
    firstName: new FormControl('',Validators.required),
    lastName: new FormControl('',Validators.required),
    email: new FormControl('',[Validators.required, Validators.email]),
    phone: new FormControl('',[Validators.required,Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$")]),
    agent: new FormControl('',Validators.required)
  });
  
  failedSubmit = false; 
  agents = ["No Agent Yet"]

  constructor(public dialog: MatDialog, private http: HttpClient,  @Inject(MAT_DIALOG_DATA) public data,private _toastService: ToastService, private dialogRef: MatDialogRef<ContactModalComponent>) { 
    this.property = data;
  }

  ngOnInit(): void {
    this.http.get(environment.API_URL + "agents")
    .subscribe(response => {
      const res = response as JSON;
      const founder = res["founder"]
      const agentsList: Array<Object> = res["agents"];
      this.agents.push(founder["name"])
      agentsList.forEach(agent => {
        this.agents.push(agent["name"])
      });
    });
  }

  async submitForm(formData: any, formDirective: FormGroupDirective) {
    var dateTimeStamp = new Date();
    let year = dateTimeStamp.getFullYear();
    this.contactForm.addControl('year', new FormControl(year));
    let day = dateTimeStamp.getDate();
    this.contactForm.addControl('day', new FormControl(day));
    this.contactForm.addControl('month', new FormControl(dateTimeStamp.getMonth()+1));
    this.contactForm.addControl('time',new FormControl(dateTimeStamp.getHours()+':'+dateTimeStamp.getMinutes()));
    this.contactForm.addControl('mls',new FormControl(this.property["ListingID"]));

    const response = await this.http.post(environment.API_URL + "submit-form", JSON.stringify(this.contactForm.value)).toPromise();
    console.log(response);
    if (response != 201) {
      this.failedSubmit = true;
      this.contactForm.reset();
      formDirective.resetForm();
    }
    else {
      this._toastService.success('An agent will reach out soon');
      this.dialogRef.close()
    }
  }
}
