import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-guide-card',
  templateUrl: './sell-guide-card.component.html',
  styleUrls: ['./sell-guide-card.component.css']
})
export class SellGuideCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  changeSlide(num): void {
    var prevSelected = document.getElementsByClassName("selected-slide")[0];
    prevSelected.classList.remove("selected-slide");
    console.log("This is the old num object: " + prevSelected);
    console.log(num);
    var selected = document.getElementById("slide-"+num);
    selected.classList.add("selected-slide");
    console.log("This is the new num object: " + selected);

    var prevSelectednum = document.getElementsByClassName("slide-selected-button")[0];
    prevSelectednum.classList.remove("slide-selected-button");
    var selectednum = document.getElementById(num);
    selectednum.classList.add("slide-selected-button");
  }
}
