import { Component, OnInit,ViewChild, HostBinding, ElementRef, AfterViewInit } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgxXml2jsonService } from 'ngx-xml2json';
import * as M from "materialize-css/dist/js/materialize.js";
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, AfterViewInit {
    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;
    
  formattedAddress = "";

  // Zillow data members
  baseZillowReviewsAPILink = "https://www.zillow.com/webservice/ProReviews.htm";
  zws_id = environment.ZWS_ID;
  screenname = "Gabali+Realty";
  zillowReviewsURL = this.baseZillowReviewsAPILink + "?zws-id=" + this.zws_id + "&screenname=" + this.screenname + "&count=6";
  zillowReviewsResponse: object;
  zillowAPICalled: Promise<boolean>;
  avgStarRating: number;
  avgStarPercent: number;
  totalReviewCount: number;
  zillowReviews;
  laredoBounds= new google.maps.LatLngBounds(
    new google.maps.LatLng(27.375255, -99.605297),
    new google.maps.LatLng(27.703629, -99.371649)
  );
  options = {
    componentRestrictions : {
      country: 'US'
    },
/*     bounds: this.laredoBounds,
    strictBounds: true */
  }
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  constructor(private http: HttpClient, 
        private xml2js: NgxXml2jsonService, 
        private router: Router,
        private ccService: NgcCookieConsentService) { }

  ngOnInit(): void {
    // subscribe to cookieconsent observables to react to main events
    /* this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }); */
  this.http.get(this.zillowReviewsURL, {responseType: 'text'})
    .subscribe(response => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(response, 'text/xml');
      const obj = this.xml2js.xmlToJson(xml);

      this.zillowReviewsResponse = obj["ProReviews:proreviewresults"]["response"]["result"];
      this.extractValues(this.zillowReviewsResponse);
      this.zillowAPICalled = Promise.resolve(true);
    });
  }
  ngAfterViewInit() {
    // no errors
    let elems = document.querySelectorAll('.carousel');
    var instance = M.Carousel.init(elems, {
      fullWidth: true,
      indicators: true
    });
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription?.unsubscribe();
    this.popupCloseSubscription?.unsubscribe();
    this.initializeSubscription?.unsubscribe();
    this.statusChangeSubscription?.unsubscribe();
    this.revokeChoiceSubscription?.unsubscribe();
    this.noCookieLawSubscription?.unsubscribe();
  }
  // Zillow functions
  private extractValues(zillowReviews: object) {
    this.avgStarRating = zillowReviews["proInfo"]["avgRating"];
    this.totalReviewCount = zillowReviews["proInfo"]["reviewCount"];
    this.zillowReviews = zillowReviews["proReviews"]["review"];
    this.avgStarPercent = this.avgStarRating / 5.0 * 100;
  }

  // Google Maps functions


  public handleAddressChange(address: any) {
    this.formattedAddress = address.formatted_address;
  }
  submitAddress() {
    this.router.navigate(['/featured-properties', { 'address': this.formattedAddress }]);
  }


}
