import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Component, OnInit,ViewChild  } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';


import { environment } from '../../environments/environment'
import { throwError } from 'rxjs';
import { Event } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-favorite-properties',
  templateUrl: './favorite-properties.component.html',
  styleUrls: ['./favorite-properties.component.css']
})
export class FavoritePropertiesComponent implements OnInit {
  
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  formattedAddress = "";

  favoriteProperties = [];

  markerWindow = null;
  markerClicked = false;

  isSmallScreen: boolean;
  displayMap = false;
  buttonText = "Map";

  favoritePropertiesAPICalled: Promise<boolean>;


  userEmail: String;
  favoriteModified = false;
  
  latitude = 27.5061111;
  longitude = -99.5072222;
  zoom = 12;
  p1: number = 1;

  mapIcon = {
    url: '../../assets/icons/rounded-rectangle.svg',
    scaledSize: {
      width: 200,
      height: 50,
    }
  }
  
  priceFilterDisplay = false;
  bedFilterDisplay = false;
  bathFilterDisplay = false;

  constructor(private http: HttpClient,
      private authService: AuthService,
      private breakpointObserver: BreakpointObserver) {
        this.isSmallScreen = breakpointObserver.isMatched('(max-width: 1200px)');
        breakpointObserver.observe([
          '(max-width: 1200px)'
        ]).subscribe(result => {
          if (result.matches) { this.isSmallScreen = true; }
          else { this.isSmallScreen = false; }
        });
      }
  isMapDisplay() { return (!this.isSmallScreen || this.displayMap); }
  isListDisplay() { return (!this.isSmallScreen || !this.displayMap); }
  toggleMapDisplay() { 
    this.displayMap = !this.displayMap;
    this.buttonText = (this.buttonText === "Map") ? "List" : "Map"; 
  }

  async ngOnInit() {
    this.userEmail = this.authService.getUserEmail();
    this.http.get(environment.API_URL + 'listings/' + this.userEmail).subscribe((resp: Object[]) => {
      this.favoriteProperties = resp;
      this.favoriteProperties.forEach(property => {this.addFavorite(property)})
    });
    this.favoritePropertiesAPICalled = Promise.resolve(true);
    
  }
  addFavorite(property: object) {
    property["favorite"] = true;
    if(property["Coordinates"]==undefined) {
      property["Coordinates"] = [1,1];
    }
    property["Price_Suffix"]= this.withSuffix(property["Price"]);
  }
  favoritePageChange(page: number) {
    const element = document.querySelector('#favorite-header')
    element.scrollIntoView();
    return page;
  }
  selectMarker(infoWindow) {
    if(this.markerWindow) {
      this.markerWindow.close();
      this.markerWindow = infoWindow;
      this.markerWindow.open();
    }
    else {
      this.markerWindow = infoWindow;
      this.markerWindow.open();
    }
  }
  clickedMap() {
    if(this.markerWindow) {
      this.markerWindow.close();
      this.markerWindow = null;
    }
  }
  hoverMarker(infoWindow) {
    if(!this.markerWindow) {
      infoWindow.open()
    }
  }
  hoverMarkerExit(infoWindow) {
    if(!this.markerWindow) {
      infoWindow.close();
    }
  }
    // Google Maps functions
  options = {
    componentRestrictions : {
      country: 'US'
    }
  }
  
  public handleAddressChange(address: any) {
    this.formattedAddress = address.formatted_address;
  }
  //function for transforming number
  withSuffix(v) {
    let value = Number(Number(v).toFixed());
    let transformVal = ""
    if(value>=1000000) {
      transformVal=(value/1000000).toFixed()+"M"
      return transformVal
    }
    if(value>=1000) {
      transformVal=(value/1000).toFixed()+"K"
      return transformVal
    }
    return value;
  }
}
