import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, FormGroupDirective } from '@angular/forms';
import { environment } from "../../environments/environment";
import { MatDialog } from '@angular/material/dialog';
import { ApiSentMessageSuccessModalComponent as successModal} from '../api-sent-message-success-modal/api-sent-message-success-modal.component';
@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {

  constructor(private http: HttpClient, public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  //firstName = new FormControl('', Validators.required);
   contactForm = new FormGroup({
    firstName: new FormControl('',Validators.required),
    lastName: new FormControl('',Validators.required),
    email: new FormControl('',[Validators.required, Validators.email]),
    phone: new FormControl('',[ Validators.required, Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$")]),
    reason: new FormControl('',Validators.required),
    message: new FormControl('',Validators.required)
  }); 

  failedSubmit = false;

  getErrorMessage() {
    if (this.contactForm.get('email').hasError('required')) {
      return 'EMAIL REQUIRED';
    }
    return this.contactForm.get('email').hasError('email') ? 'NOT A VALID EMAIL' : '';
  }
  openSuccessModal() {
    const dialogRef = this.dialog.open(successModal,{ width: '250px' });
  }

  async submitForm(formData: any, formDirective: FormGroupDirective) {
    var dateTimeStamp = new Date();
    let year = dateTimeStamp.getFullYear();
    this.contactForm.addControl('year', new FormControl(year));
    let day = dateTimeStamp.getDate();
    this.contactForm.addControl('day', new FormControl(day));
    this.contactForm.addControl('month', new FormControl(dateTimeStamp.getMonth()+1));
    this.contactForm.addControl('time',new FormControl(dateTimeStamp.getHours()+':'+dateTimeStamp.getMinutes()));

    const response = await this.http.post(environment.API_URL + "contact-form", JSON.stringify(this.contactForm.value)).toPromise();
    if (response != 201) {
      this.failedSubmit = true;
    }
    else {
      this.contactForm.reset();
      formDirective.resetForm();
      this.openSuccessModal();
    }
  }
}
