import { Injectable } from '@angular/core';
import {Component} from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService implements CanActivate {

  constructor(public authService: AuthService, public dialog: MatDialog, public router: Router, public route: ActivatedRoute) { }

  async canActivate() {
/*     if (this.authService.getUserEmail() === "gabalidevelopment@gmail.com") { */
  if (this.authService.isAdmin) {
    return true;
  }
    this.dialog.open(InvalidAccountDialog)
    return false;
  }
}

@Component({
  selector: 'invalid-account-dialog',
  template: `
  <div>
    <h1>Please Login Into An Admin Account</h1>
  </div>
    
  `,
  styles: [
    `
    div {
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h1 {
      text-align: center;
    }
    `
  ]
})
export class InvalidAccountDialog {}