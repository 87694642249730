<div class="guide">
    <div class="header-photo">
        <div class="guide-header-column">
            <div class="column-item bold">Buyer's Guide</div>
            <hr>
            <div class="column-item bold-light">Get information on how to buy your next home. Rest assured, the best and easiest experience in buying a home is only a phone call away</div>
        </div>
    </div>
    <div class="body-introduction">
        <p>Shopping for your new home can be a daunting experience. That’s why it’s important to have the proper professional guidance. We provide this at Gabali Realty to give you the best possible experience. Gabali Realty specializes in finding the perfect home, in the area you want, and within the budget you can afford.<br><br>
            Simplifying the process by breaking it down in these 5 steps ensures a quick, easy, and enjoyable home-buying experience you will never forget.
        </p>
    </div>
    <app-buy-guide-card></app-buy-guide-card>
    <div class="body-end">
        <h1>Tips to help your find your home</h1>
        <p>
            In addition to our local knowledge on the current market, we offer online tools that can help make your initial search as easy as possible.
        </p>
        <p><strong>Try Our Interactive Map Search</strong></p>
        <p>
            The map available on our search page makes it easy to find homes for sale in exactly the location you want. Take a look and get one-step closer to finding the home you love.
        </p>
        <p><strong>Create an Account on Our Website</strong></p>
        <p>
            Sign-up fast and easy to enjoy a better home search experience! You will be able to save your favorite listings making your search smooth and user-friendly!
        </p>
    </div>
</div>