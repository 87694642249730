import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastService } from 'angular-toastify';

@Component({
  selector: 'app-estimate-modal',
  templateUrl: './estimate-modal.component.html',
  styleUrls: ['./estimate-modal.component.css']
})
export class EstimateModalComponent implements OnInit {
  contactForm = new FormGroup({
    firstName: new FormControl('',Validators.required),
    lastName: new FormControl('',Validators.required),
    email: new FormControl('',[Validators.required, Validators.email]),
    phone: new FormControl('',[ Validators.required, Validators.pattern("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$")]),
  });
  failedSubmit = false;
  success = false;
  address;
  constructor( private http: HttpClient, @Inject(MAT_DIALOG_DATA) public data, private _toastService: ToastService, private dialogRef: MatDialogRef<EstimateModalComponent>) { this.address = data}

  ngOnInit(): void {
  }
  async submitForm(formData: any, formDirective: FormGroupDirective) {
    var dateTimeStamp = new Date();
    let year = dateTimeStamp.getFullYear();
    this.contactForm.addControl('year', new FormControl(year));
    let day = dateTimeStamp.getDate();
    this.contactForm.addControl('day', new FormControl(day));
    this.contactForm.addControl('month', new FormControl(dateTimeStamp.getMonth()+1));
    this.contactForm.addControl('time',new FormControl(dateTimeStamp.getHours()+':'+dateTimeStamp.getMinutes()));
    this.contactForm.addControl('address', new FormControl(this.address));
    const response = await this.http.post(environment.API_URL + "estimate-form", JSON.stringify(this.contactForm.value)).toPromise();
    if (response != 201) {
      this.failedSubmit = true;
      this.contactForm.reset();
      formDirective.resetForm();
    }
    else {
      this._toastService.success('An agent will reach out soon');
      this.dialogRef.close()
    }
  }

}
