<div class="agent-panel">
    <!-- <strong>Get to know us here at</strong> 
    <img src="../../assets/gabali logo.png" alt="">
    <h1>Gabali Realty</h1> -->
    <h1>Meet our Agents</h1>
    <h3>Local knowledge. Local experts.</h3>
    <div *ngIf="agents" class="agent-cards">
        <div class="agent-card">
            <h3>{{ founder["name"] }}</h3>
            <img [src]= "founder['img-url']" />
            <p> {{ founder["description"] }} </p>
            <div class="contact-buttons">
                <a href="tel:{{founder['phone']}}" mat-raised-button color="primary">
                    <span class="material-icons">call</span>
                    <span>Call Me</span>
                </a>
                <a href="mailto:{{founder['email']}}" mat-raised-button color="primary">
                    <span class="material-icons">email</span>
                    <span> Email Me</span>
                </a>
            </div>
        </div>
        <div *ngFor="let agent of agents" class="agent-card">
            <h3>{{ agent["name"] }}</h3>
            <img [src]= "agent['img-url']" />
            <p> {{ agent["description"] }} </p>
            <div class="contact-buttons">
                <a href="tel:{{agent['phone']}}" mat-raised-button color="primary">
                    <span class="material-icons">call</span>
                    <span>Call Me</span>
                </a>
                <a href="mailto:{{agent['email']}}" mat-raised-button color="primary">
                    <span class="material-icons">email</span>
                    <span> Email Me</span>
                </a>
            </div>
        </div>
    </div>
</div>

<!--<img src="https://storage.googleapis.com/gabali-realty.appspot.com/PropertyPictures/20120131/uploaded_pic.jpg" alt="">-->
