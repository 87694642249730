import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoadPropertiesService {
  static featuredResidentialProperties: Array<object> = JSON.parse(sessionStorage.getItem('featuredResidentialProperties'));
  static featuredCommercialProperties: Array<object> = JSON.parse(sessionStorage.getItem('featuredCommercialProperties'));
  static featuredIndustrialProperties: Array<object> = JSON.parse(sessionStorage.getItem('featuredIndustrialProperties'));
  static featuredLandProperties: Array<object> = JSON.parse(sessionStorage.getItem('featuredLandProperties'));
  static allResidentialProperties: Array<object> = JSON.parse(sessionStorage.getItem('allResidentialProperties'));
  static allCommercialProperties: Array<object> = JSON.parse(sessionStorage.getItem('allCommercialProperties'));
  static allIndustrialProperties: Array<object> = JSON.parse(sessionStorage.getItem('allIndustrialProperties'));
  static allLandProperties: Array<object> = JSON.parse(sessionStorage.getItem('allLandProperties'));
  static allPropertiesLoaded: Promise<Boolean>;

  constructor() {}

  static async loadProperty(url: string, http: HttpClient) {
/*     const response = await http.get(url).pipe(timeout(60000), catchError(e => { 
      const response =  http.post(url,JSON.stringify({"useRETS": "false"}))
      return response;
    })).toPromise(); */
    const response = await http.get(url).toPromise(); 
    return (response as Array<Object>);
  }

  static async loadProperties(http: HttpClient) {
    // get the property listings 
    localStorage.clear()
    sessionStorage.clear()
    if (this.featuredResidentialProperties == null) {
      this.featuredResidentialProperties = await this.loadProperty(environment.API_URL+"listings/featured/residential", http);
      
      
      //sessionStorage.setItem('featuredResidentialProperties', JSON.stringify(this.featuredResidentialProperties));
    }
    if (this.featuredCommercialProperties== null) {
       this.featuredCommercialProperties = await this.loadProperty(environment.API_URL+"listings/featured/commercial", http);
       //sessionStorage.setItem('featuredCommercialProperties', JSON.stringify(this.featuredCommercialProperties));
    }
    if (this.featuredIndustrialProperties == null) {
      this.featuredIndustrialProperties = await this.loadProperty(environment.API_URL+"listings/featured/industrial", http);
      //sessionStorage.setItem('featuredIndustrialProperties', JSON.stringify(this.featuredIndustrialProperties));
    }
    if (this.featuredLandProperties == null) {
      this.featuredLandProperties = await this.loadProperty(environment.API_URL+"listings/featured/land", http);
      //sessionStorage.setItem('featuredLandProperties', JSON.stringify(this.featuredLandProperties));
    }

    if (this.allResidentialProperties == null) {
      this.allResidentialProperties = await this.loadProperty(environment.API_URL+"listings/residential", http);
      //sessionStorage.setItem('allResidentialProperties', JSON.stringify(this.allResidentialProperties));
    }
    if (this.allCommercialProperties == null) {
      this.allCommercialProperties = await this.loadProperty(environment.API_URL+"listings/commercial", http);
      //sessionStorage.setItem('allCommercialProperties', JSON.stringify(this.allCommercialProperties));
    }
    if (this.allIndustrialProperties == null) {
      this.allIndustrialProperties = await this.loadProperty(environment.API_URL+"listings/industrial", http);
      //sessionStorage.setItem('allIndustrialProperties', JSON.stringify(this.allIndustrialProperties));
    }
    if (this.allLandProperties == null) {
      this.allLandProperties = await this.loadProperty(environment.API_URL+"listings/land", http);
      //sessionStorage.setItem('allLandProperties', JSON.stringify(this.allLandProperties));
    }
    

    this.allPropertiesLoaded = Promise.resolve(true);
  }

}
