import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select'; 
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatCardModule } from '@angular/material/card';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ToastService, AngularToastifyModule } from 'angular-toastify';
import { MatRippleModule } from '@angular/material/core';
import 'hammerjs';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BuyerGuideComponent } from './buyer-guide/buyer-guide.component';
import { BuyGuideCardComponent } from './buy-guide-card/buy-guide-card.component';
import { SellerGuideComponent } from './seller-guide/seller-guide.component';
import { SellGuideCardComponent } from './sell-guide-card/sell-guide-card.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { HomeEstimatePageComponent } from './home-estimate-page/home-estimate-page.component';
import { AgentsComponent } from './agents/agents.component';
import { FeaturedPropertiesComponent } from './featured-properties/featured-properties.component';
import { ResidentialCardComponent } from './residential-card/residential-card.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AccountComponent } from './account/account.component';
import { ModalResidentialComponent } from './modal-residential/modal-residential.component';
import { environment } from '../environments/environment';
import { ZestimateComponent } from './zestimate/zestimate.component';
import { FavoritePropertiesComponent } from './favorite-properties/favorite-properties.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { ForgotPasswordComponent } from './login/forgot.password.component';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import { ApiSentMessageSuccessModalComponent } from './api-sent-message-success-modal/api-sent-message-success-modal.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { TourModalComponent } from './tour-modal/tour-modal.component';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { EstimateModalComponent } from './estimate-modal/estimate-modal.component';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "top-right",
  theme: "classic",
  type: "info",
  palette: {
    popup: {
      background: "#12395e",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: '#ea872d',
      text: '#ffffff',
      border: "transparent"
    }
  },
  content: {
    message: "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: "https://cookiesandyou.com",
    policy: "Cookie Policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BuyerGuideComponent,
    BuyGuideCardComponent,
    SellerGuideComponent,
    SellGuideCardComponent,
    ContactPageComponent,
    HomeEstimatePageComponent,
    AgentsComponent,
    FeaturedPropertiesComponent,
    ResidentialCardComponent,
    LoginComponent,
    SignupComponent,
    AccountComponent,
    ModalResidentialComponent,
    ZestimateComponent,
    FavoritePropertiesComponent,
    AdminPageComponent,
    ForgotPasswordComponent,
    ApiSentMessageSuccessModalComponent,
    ContactModalComponent,
    TourModalComponent,
    ShareModalComponent,
    EstimateModalComponent

  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    HttpClientModule,
    GooglePlaceModule,
    MatCardModule,
    NgxGalleryModule,
    NgxPaginationModule,
    MatSidenavModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ClipboardModule,
    AngularToastifyModule,
    MatRippleModule,
    AgmCoreModule.forRoot({
      apiKey: environment.MAPS_KEY
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AgmMarkerClustererModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
  ],
  providers: [ToastService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
