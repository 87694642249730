
import { Component, OnInit, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoginPopupService } from '../services/login-popup.service';
import {MatDialog} from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';
import { LoadPropertiesService } from '../services/load-properties.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  // Data members
  sidenavOpen: boolean = false;
  buyMenuOpen: boolean = false;
  accountMenuOpen: boolean = false;
  sellMenuOpen: boolean = false;
  openedModal: boolean = false;
  isLoggedIn: Boolean = false;
  isAdmin: Boolean = false;
  sideNavConfig: {
    from: "right",
    
  }
  routes = [];
  // Methods
  constructor(public auth: AuthService, 
    public loginPopup: LoginPopupService, 
    private router: Router, 
    private http: HttpClient, 
    public dialog: MatDialog) { 
    this.loginPopup.subject.subscribe(val => {
      if (val !== '') {
        this.openSignIn();
      }
    })
  }
  async ngOnInit() {
    this.isLoggedIn = await this.auth.checkAuthenticated();
    this.auth.isLoggedIn.subscribe(arg => {
      this.isLoggedIn = arg;
      if(this.auth.isAdmin) {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
      }
    });
/*     if (!LoadPropertiesService.allPropertiesLoaded) {
      LoadPropertiesService.loadProperties(this.http);
    } */
  }
  @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if(width < 900) {
        let element = document.querySelector('mat-drawer-container');
        if (window.pageYOffset <= element.clientHeight && this.isHeaderTransparentMobile()) {
          element.classList.add('mobile-header-transparent');
          element.classList.remove('mobile-header');  
        }
        else {
          element.classList.remove('mobile-header-transparent');
          element.classList.add('mobile-header');
        }
      }
      else {
        let element = document.querySelector('header');
        if (window.pageYOffset <= element.clientHeight && this.isHeaderTransparent()) {
          element.classList.add('desktop-header-transparent');
          element.classList.remove('desktop-header');  
        }
        else {
          element.classList.remove('desktop-header-transparent');
          element.classList.add('desktop-header');
        }
      }

    }

  changeHeaderClass() {
    return this.isHeaderTransparent() ? "desktop-header-transparent" : "desktop-header";
  }
  changeHeaderClassMobile() {
    return this.isHeaderTransparentMobile() ? "mobile-header-transparent" : "mobile-header";
  }
  
  isHeaderTransparent(): boolean {
    const element = document.querySelector('header');
    const moved = window.pageYOffset > element.clientHeight;
    const headerTransparentPages = ['/home', '/seller-guide', '/buyer-guide', '/home-estimate-page','/zestimate'];
    return !moved && headerTransparentPages.includes(this.router.url.split('?')[0]);
  }
  isHeaderTransparentMobile(): boolean {
    const element = document.querySelector('mat-drawer-container');
    const moved = window.pageYOffset > element.clientHeight;
    const headerTransparentPages = ['/home', '/seller-guide', '/buyer-guide', '/home-estimate-page','/zestimate'];
    return !moved && headerTransparentPages.includes(this.router.url.split('?')[0]);
  }

  toggleBuyMenu() {
      document.getElementById("sidenav-buy-drpdwn").style.display = this.buyMenuOpen ? "none" : "flex";
      if (this.buyMenuOpen) {
        document.getElementById("buy-down-arrow").style.display = "inline";
        document.getElementById("buy-up-arrow").style.display = "none";
      }
      else {
        document.getElementById("buy-down-arrow").style.display = "none";
        document.getElementById("buy-up-arrow").style.display = "inline";
      }
      this.buyMenuOpen = !this.buyMenuOpen;
  }
  toggleAccountMenu() {
    if(this.isLoggedIn) {
      document.getElementById("sidenav-account-logged-in-drpdwn").style.display = this.accountMenuOpen ? "none" : "flex";
      if (this.accountMenuOpen) {
        document.getElementById("account-down-arrow").style.display = "inline";
        document.getElementById("account-up-arrow").style.display = "none";
      }
      else {
        document.getElementById("account-down-arrow").style.display = "none";
        document.getElementById("account-up-arrow").style.display = "inline";
      }
      this.accountMenuOpen = !this.accountMenuOpen;
    }
    else {
      document.getElementById("sidenav-account-logged-out-drpdwn").style.display = this.accountMenuOpen ? "none" : "flex";
      if (this.accountMenuOpen) {
        document.getElementById("account-down-arrow").style.display = "inline";
        document.getElementById("account-up-arrow").style.display = "none";
      }
      else {
        document.getElementById("account-down-arrow").style.display = "none";
        document.getElementById("account-up-arrow").style.display = "inline";
      }
      this.accountMenuOpen = !this.accountMenuOpen;
    }
  }
  logout() {
    this.auth.logout('/home');
  }
  openSignIn() {
    const dialogRef = this.dialog.open(LoginComponent,{ maxWidth: '100vw', maxHeight: '-webkit-fill-available' });
    const subscribeDialog = dialogRef.componentInstance.switchModalSignUp.subscribe((data) => {
      this.dialog.closeAll();
      this.openSignUp();
    });
    dialogRef.componentInstance.loginCloseModal.subscribe(() => {
      this.dialog.closeAll();
    })
  }
  openSignUp() {
    const dialogRef = this.dialog.open(SignupComponent,{ maxWidth: '100vw', maxHeight: '-webkit-fill-available' });
    const subscribeDialog = dialogRef.componentInstance.switchModalSignIn.subscribe((data) => {
      this.dialog.closeAll();
      this.openSignIn();
    });
    dialogRef.componentInstance.signupCloseModal.subscribe(() => {
      this.dialog.closeAll();
    })
  }
  toggleSellMenu() {
    document.getElementById("sidenav-sell-drpdwn").style.display = this.sellMenuOpen ? "none" : "flex";
      if (this.sellMenuOpen) {
        document.getElementById("sell-down-arrow").style.display = "inline";
        document.getElementById("sell-up-arrow").style.display = "none";
      }
      else {
        document.getElementById("sell-down-arrow").style.display = "none";
        document.getElementById("sell-up-arrow").style.display = "inline";
      }
      this.sellMenuOpen = !this.sellMenuOpen;

  }
}