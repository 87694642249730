<div class="search-bar">
    <input ngx-google-places-autocomplete #searchBar placeholder="Search by Address or MLS #" autocapitalize="none" (keyup.enter)="handleSearch($event)" [options]="options" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" />    <span class="search-close" (click)="clearAddressFilter()">&times;</span>
    <div class="filters">
        <div class="type">
            <button class="filter-button" mat-raised-button color="accent" (click)="typeFilterClicked()">Type</button>
            <div class="filter-container type-filter" *ngIf="residentialFiltersDisplay.typeFilterDisplay">
                <div>
                    <span class="bold">Property Types</span> 
                    <span (click)="typeFilterClicked()" id="close-types-filter" class="material-icons"> clear</span>
                </div>
                <mat-radio-group (change)="filterTypeChange($event)" aria-label="Select a property type">
                    <mat-radio-button value="residential" [checked] = "getCurrentTypeFilter() === 'residential'">Residential</mat-radio-button>
                    <mat-radio-button value="commercial" [checked] = "getCurrentTypeFilter() === 'commercial'">Commercial</mat-radio-button>
                    <mat-radio-button value="industrial" [checked] = "getCurrentTypeFilter() === 'industrial'">Industrial</mat-radio-button>
                    <mat-radio-button value="land" [checked] = "getCurrentTypeFilter() === 'land'">Land</mat-radio-button>
                    <mat-radio-button value="allTypes" [checked] = "getCurrentTypeFilter() === 'all'">All Types</mat-radio-button>
                  </mat-radio-group>
            </div>
        </div>
        <div id="price">
            <button class="filter-button" mat-raised-button color="primary" (click)="priceFilterResidentialClicked()">Price</button>
            <div class="filter-container price-filter" *ngIf="residentialFiltersDisplay.priceFilterDisplay">
                <div>
                    <span class="bold">Price</span> 
                    <span class="light" (click)="clearPriceFilter()" >Clear</span>
                    <span (click)="priceFilterResidentialClicked()" id="close-price-filter" class="material-icons"> clear</span>
                </div>
                <div class="filter-range">
                    <mat-form-field  appearance="outline">
                        <mat-label>Min</mat-label>
                        <input type="number" matInput [matAutocomplete]="auto1" [formControl]="minPriceControl"> 
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #auto1="matAutocomplete" dropdown-arrow="true" [displayWith]="displayFilter" (optionSelected)="minPriceFilterChange($event)">
                            <mat-option *ngFor="let option of residentialFilters.listOfPriceMinOptions " [value]="option">{{ option | currency: 'USD' : 'symbol' : '1.0-0'}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <span> to </span>
                    <mat-form-field  appearance="outline">
                        <mat-label>Max</mat-label>
                        <input type="number" matInput [matAutocomplete]="auto2" [(formControl)]="maxPriceControl"> 
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #auto2="matAutocomplete" dropdown-arrow="true" [displayWith]="displayFilter" (optionSelected)="maxPriceFilterChange($event)">
                            <mat-option *ngFor="let option of residentialFilters.listOfPriceMaxOptions " [value]="option">{{ option | currency: 'USD' : 'symbol' : '1.0-0'}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div id="bed">
            <button class="filter-button" mat-raised-button color="primary" *ngIf="this.typesToDisplay.residentialProperties" (click)="bedFilterClicked()">Beds</button>
            <div id="bed-filter" class="filter-container bed-filter"  *ngIf="residentialFiltersDisplay.bedFilterDisplay">
                <div>
                    <span class="bold">Beds</span> 
                    <span class="light" (click)="clearBedsFilter()">Clear</span>
                    <span (click)="bedFilterClicked()" id="close-types-filter" class="material-icons"> clear</span>
                </div>
                <div class="filter-increment-range">
                    <div (click)="bedFilterItemClicked($event)" [class]="getBedFilterClass('bed-filter-one')" id="bed-filter-one" >1+</div>
                    <div (click)="bedFilterItemClicked($event)" [class]="getBedFilterClass('bed-filter-two')" id="bed-filter-two">2+</div>
                    <div (click)="bedFilterItemClicked($event)" [class]="getBedFilterClass('bed-filter-three')" id="bed-filter-three">3+</div>
                    <div (click)="bedFilterItemClicked($event)" [class]="getBedFilterClass('bed-filter-four')" id="bed-filter-four">4+</div>
                </div>
            </div>
        </div>
        <div id="baths">
            <button class="filter-button" mat-raised-button *ngIf="this.typesToDisplay.residentialProperties" color="primary" (click)="bathFilterClicked()">Baths</button>
            <div class="filter-container bath-filter"  *ngIf="residentialFiltersDisplay.bathFilterDisplay">
                <div>
                    <span class="bold">Baths</span> 
                    <span class="light" (click)="clearBathsFilter()" >Clear</span>
                    <span (click)="bathFilterClicked()" id="close-baths-filter" class="material-icons"> clear</span>
                </div>
                <div class="filter-range">
                    <mat-form-field  appearance="outline">
                        <mat-label>Min</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto3" [(formControl)]="minBathControl"> 
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #auto3="matAutocomplete" dropdown-arrow="true" (optionSelected)="minBathFilterChange($event)">
                            <mat-option *ngFor="let option of residentialFilters.listOfBathMinOptions " [value]="option">{{ option }}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <span> to </span>
                    <mat-form-field  appearance="outline">
                        <mat-label>Max</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto4" [(formControl)]="maxBathControl"> 
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #auto4="matAutocomplete" dropdown-arrow="true" (optionSelected)="maxBathFilterChange($event)">
                            <mat-option *ngFor="let option of residentialFilters.listOfBathMaxOptions " [value]="option">{{ option }}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div id="area">
            <button class="filter-button" mat-raised-button *ngIf="!this.typesToDisplay.residentialProperties && this.typesToDisplay.landProperties" color="primary" (click)="areaFilterClicked()">Area</button>
            <div class="filter-container area-filter"  *ngIf="landFiltersDisplay.areaFilterDisplay">
                <div>
                    <span class="bold">Acres</span>
                     <span class="light" (click)="clearAreaFilter()" >Clear</span>
                     <span (click)="areaFilterClicked()" id="close-types-filter" class="material-icons"> clear</span>
                    </div>
                <div class="filter-range">
                    <mat-form-field  appearance="outline">
                        <mat-label>Min</mat-label>
                        <input  type="number" matInput (input)="minAreaFilterChange($event)">
                    </mat-form-field>
                    <span> to </span>
                    <mat-form-field  appearance="outline">
                        <mat-label>Max</mat-label>
                        <input type="number" matInput (input)="maxAreaFilterChange($event)"> 
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div id="size">
            <button class="filter-button" mat-raised-button *ngIf="!this.typesToDisplay.residentialProperties && (this.typesToDisplay.commercialProperties || this.typesToDisplay.industrialProperties) " color="primary" (click)="sizeFilterClicked()">Size</button>
            <div class="filter-container area-filter"  *ngIf="commercialFiltersDisplay.sizeFilterDisplay">
                <div>
                    <span class="bold">Size</span>
                     <span class="light" (click)="clearSizeFilter()" >Clear</span>
                     <span (click)="sizeFilterClicked()" id="close-types-filter" class="material-icons"> clear</span>
                    </div>
                <div class="filter-range">
                    <mat-form-field  appearance="outline">
                        <mat-label>Min</mat-label>
                        <input  type="number" matInput (input)="minSizeFilterChange($event)">
                    </mat-form-field>
                    <span> to </span>
                    <mat-form-field  appearance="outline">
                        <mat-label>Max</mat-label>
                        <input type="number" matInput (input)="maxSizeFilterChange($event)"> 
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="sort">
            <button class="filter-button" mat-raised-button color="primary" (click)="sortFilterResidentialClicked()">Sort</button>
            <div class="filter-container sort-filter" *ngIf="residentialFiltersDisplay.sortFilterDisplay">
                <div>
                    <span class="bold">Sort By Price</span>
                    <span (click)="sortFilterResidentialClicked()" id="close-types-filter" class="material-icons"> clear</span>
                    </div>
                <span class="light" style="margin-top:5px; margin-left:0;" (click)="clearSortFilter()" >Clear</span>
                <mat-radio-group (change)="sortByPrice($event)" [formControl]="sortFilterControl" aria-label="Select sort by: ascending or descending">
                    <mat-radio-button value="ascending" >Ascending</mat-radio-button>
                    <mat-radio-button value="descending" >Descending</mat-radio-button>
                  </mat-radio-group>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <button mat-raised-button class="map-toggle" (click)="toggleMapDisplay()"> {{ buttonText }} </button>
    <div class="left-side" *ngIf="isListDisplay()">
        <div class="loaded-cards">
            <div id="featured-header" *ngIf="(featuredLandAPICalled | async ) && (featuredProperties.length>0)">
                <div class="header"><span class="header-text">Featured Properties</span></div>
                <div class="card-collection"><app-residential-card [hasFavorite]="true" *ngFor="let property of 
                    featuredProperties
                     | paginate: { id: 'p1', itemsPerPage: 3, currentPage: p1,totalItems: featuredProperties.length }" [propertyType]="property['Type']"
                      [property]="property" class="card" [sharedProperty]="this.sharedProperty" ></app-residential-card></div>
                <div class="flex-center"><pagination-controls (pageChange)="p1 = featuredPageChange($event)" id="p1" responsive="responsive" autoHide="true"></pagination-controls></div>
            </div>
            <div id="residential-header" *ngIf="(residentialAPICalled | async) && (typesToDisplay.residentialProperties)" >
                <div class="header"><span class="header-text">Residential</span></div>
                <div class="empty-property-search" *ngIf="!(allResidentialProperties.length>0)" >
                    <img src="../../assets/no_results.png">
                </div>
                <div class="card-collection"><app-residential-card [hasFavorite]="true" *ngFor="let property of allResidentialProperties 
                     | paginate: { id: 'p2', itemsPerPage: 12, currentPage: p2, totalItems: allResidentialProperties.length}" [propertyType]="property['Type']" 
                     [property]="property" class="card" [sharedProperty]="this.sharedProperty"></app-residential-card></div>
                <div class="flex-center"><pagination-controls (pageChange)="p2 = residentialPageChange($event)" id="p2" responsive="responsive" autoHide="true"></pagination-controls></div>
            </div>

            <div id="commercial-header" *ngIf="(commercialAPICalled | async) && (typesToDisplay.commercialProperties)">
                <div class="header"><span class="header-text">Commercial</span></div>
                <div class="empty-property-search" *ngIf="!(allCommercialProperties.length>0)" >
                    <img src="../../assets/no_results.png">
                </div>
                <div class="card-collection"><app-residential-card [hasFavorite]="true" propertyType="commercial"*ngFor=" let property of allCommercialProperties 
                     | paginate: { id: 'p3', itemsPerPage: 12, currentPage: p3, totalItems: allCommercialProperties.length}" [propertyType]="property['Type']" [property]="property" 
                     class="card" [sharedProperty]="this.sharedProperty"></app-residential-card></div>
                <div class="flex-center"><pagination-controls (pageChange)="p3 = commercialPageChange($event)" id="p3" responsive="responsive" autoHide="true"></pagination-controls></div>
            </div>

            <div id="industrial-header" *ngIf="(industrialAPICalled | async) && (typesToDisplay.industrialProperties)">
                <div class="header"><span class="header-text">Industrial</span></div>
                <div class="empty-property-search" *ngIf="!(allIndustrialProperties.length>0)" >
                    <img src="../../assets/no_results.png">
                </div>
                <div class="card-collection"><app-residential-card [hasFavorite]="true" propertyType="industrial" *ngFor="let property of allIndustrialProperties 
                     | paginate: { id: 'p4', itemsPerPage: 12, currentPage: p4, totalItems: allIndustrialProperties.length}" [propertyType]="property['Type']" [property]="property" 
                     class="card" [sharedProperty]="this.sharedProperty"></app-residential-card></div>
                <div class="flex-center"><pagination-controls (pageChange)="p4 = industrialPageChange($event)" id="p4" responsive="responsive" autoHide="true"></pagination-controls></div>
            </div>

            <div id="land-header" *ngIf="(landAPICalled | async) && (typesToDisplay.landProperties)">
                <div class="header"><span class="header-text">Land</span></div>
                <div class="empty-property-search" *ngIf="!(allLandProperties.length>0)" >
                    <img src="../../assets/no_results.png">
                </div>
                <div class="card-collection"><app-residential-card propertyType="land" [hasFavorite]="true" *ngFor="let property of allLandProperties 
                    | paginate: { id: 'p5', itemsPerPage: 12, currentPage: p5, totalItems: allLandProperties.length}" [propertyType]="property['Type']" [property]="property" 
                    class="card" [sharedProperty]="this.sharedProperty"></app-residential-card></div>
                <div class="flex-center"><pagination-controls (pageChange)="p5 = landPageChange($event)" id="p5" responsive="responsive" autoHide="true"></pagination-controls></div>    
            </div>
        </div>
        <div class="loaded-cards" *ngIf="!(featuredResidentialAPICalled | async )">
            <div class="loading-header"><div class="header-text" style="color:transparent;">filler</div></div>
            <div class="card-collection">
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
                <div class="card">
                    <div class="img-loading"></div>
                    <div class="field-loading"></div>
                    <div class="field-loading"></div>
                </div>
            </div>
    </div>
        
    </div>
    <div class="map" *ngIf="isMapDisplay()">
        <agm-map *ngIf="showMap" #gm [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapClick)="clickedMap()" gestureHandling="greedy">
            <agm-marker-cluster *ngIf="showMarkers && landAPICalled | async" imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                <!-- <agm-marker *ngFor="let property of allResidentialProperties" 
                [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
                [iconUrl]="mapIcon" animation="DROP" openInfoWindow="true"
                (markerClick)="selectMarker(infoWindow)" [label]="{color: 'white', text: '$' + property['Price_Suffix']}"
                (mouseOver)="hoverMarker(infoWindow)" (mouseOut)="hoverMarkerExit(infoWindow)">
                <agm-info-window maxWidth="324" (infoWindowClose)="clickedMap()" class="infoWindow" #infoWindow>
                    <app-residential-card [hasFavorite]="false" [property]="property" [propertyType]="property['Type']" class="card"></app-residential-card>
                </agm-info-window>
                </agm-marker> -->
                <div *ngIf="this.typesToDisplay.residentialProperties && this.typesToDisplay.commercialProperties">
                    <agm-marker *ngFor="let property of allResidentialProperties.concat(allCommercialProperties).concat(allIndustrialProperties).concat(allLandProperties)" 
                    [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
                    [iconUrl]="mapIcon" animation="DROP" openInfoWindow="true"
                    (markerClick)="selectMarker(infoWindow)" [label]="{color: 'white', text: '$' + property['Price_Suffix']}"
                    (mouseOver)="hoverMarker(infoWindow)" (mouseOut)="hoverMarkerExit(infoWindow)">
                    <agm-info-window maxWidth="324" (infoWindowClose)="clickedMap()" class="infoWindow" #infoWindow>
                        <app-residential-card [hasFavorite]="false" [property]="property" [propertyType]="property['Type']" class="card"></app-residential-card>
                    </agm-info-window>
                    </agm-marker>
                </div>
                <div *ngIf="this.typesToDisplay.residentialProperties && !this.typesToDisplay.commercialProperties">
                    <agm-marker *ngFor="let property of allResidentialProperties" 
                    [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
                    [iconUrl]="mapIcon" animation="DROP" openInfoWindow="true"
                    (markerClick)="selectMarker(infoWindow)" [label]="{color: 'white', text: '$' + property['Price_Suffix']}"
                    (mouseOver)="hoverMarker(infoWindow)" (mouseOut)="hoverMarkerExit(infoWindow)">
                    <agm-info-window maxWidth="324" (infoWindowClose)="clickedMap()" class="infoWindow" #infoWindow>
                        <app-residential-card [hasFavorite]="false" [property]="property" [propertyType]="property['Type']" class="card"></app-residential-card>
                    </agm-info-window>
                    </agm-marker>
                </div>
                <div *ngIf="this.typesToDisplay.commercialProperties && !this.typesToDisplay.residentialProperties">
                    <agm-marker *ngFor="let property of allCommercialProperties" 
                        [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
                        [iconUrl]="mapIcon" animation="DROP" openInfoWindow="true"
                        (markerClick)="selectMarker(infoWindow)" [label]="{color: 'white', text: '$' + property['Price_Suffix']}"
                        (mouseOver)="hoverMarker(infoWindow)" (mouseOut)="hoverMarkerExit(infoWindow)">
                        <agm-info-window maxWidth="324" (infoWindowClose)="clickedMap()" class="infoWindow" #infoWindow>
                            <app-residential-card [hasFavorite]="false" [property]="property" [propertyType]="property['Type']" class="card"></app-residential-card>
                        </agm-info-window>
                    </agm-marker>
                </div>
                <div *ngIf="this.typesToDisplay.industrialProperties && !this.typesToDisplay.residentialProperties">
                    <agm-marker *ngFor="let property of allIndustrialProperties" 
                        [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
                        [iconUrl]="mapIcon" animation="DROP" openInfoWindow="true"
                        (markerClick)="selectMarker(infoWindow)" [label]="{color: 'white', text: '$' + property['Price_Suffix']}"
                        (mouseOver)="hoverMarker(infoWindow)" (mouseOut)="hoverMarkerExit(infoWindow)">
                        <agm-info-window maxWidth="324" (infoWindowClose)="clickedMap()" class="infoWindow" #infoWindow>
                            <app-residential-card [hasFavorite]="false" [property]="property" [propertyType]="property['Type']" class="card"></app-residential-card>
                        </agm-info-window>
                    </agm-marker>
                </div>
                <div *ngIf="this.typesToDisplay.landProperties && !this.typesToDisplay.residentialProperties">
                    <agm-marker *ngFor="let property of allLandProperties" 
                        [latitude]="property['Coordinates'][1]" [longitude]="property['Coordinates'][0]"  
                        [iconUrl]="mapIcon" animation="DROP" openInfoWindow="true"
                        (markerClick)="selectMarker(infoWindow)" [label]="{color: 'white', text: '$' + property['Price_Suffix']}"
                        (mouseOver)="hoverMarker(infoWindow)" (mouseOut)="hoverMarkerExit(infoWindow)">
                        <agm-info-window maxWidth="324" (infoWindowClose)="clickedMap()" class="infoWindow" #infoWindow>
                            <app-residential-card [hasFavorite]="false" [property]="property" [propertyType]="property['Type']" class="card"></app-residential-card>
                        </agm-info-window>
                    </agm-marker>
                </div>
          </agm-marker-cluster> 
        </agm-map>
    </div>

</div>
