
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ElementRef, HostListener, NgModule } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { LoadPropertiesService } from "../services/load-properties.service";
import { Component, OnInit, ViewChild, ViewChildren, QueryList  } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { FormGroup, FormControl } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { environment } from '../../environments/environment'
import {  throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { ResidentialCardComponent } from '../residential-card/residential-card.component';


@Component({
  selector: 'app-featured-properties',
  templateUrl: './featured-properties.component.html',
  styleUrls: ['./featured-properties.component.css'],
})
export class FeaturedPropertiesComponent implements OnInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild("searchBar") private searchBar;

  showMap = false;
  showMarkers = false;
  sharedProperty;
  formattedAddress = "Search";
  property; 
  featuredResidentialProperties;
  featuredCommercialProperties;
  featuredIndustrialProperties;
  featuredLandProperties;
  featuredProperties;
  allResidentialProperties;
  allCommercialProperties;
  allIndustrialProperties;
  allLandProperties;

  unfilteredFeaturedResidentialProperties;
  unfilteredFeaturedCommercialProperties;
  unfilteredFeaturedIndustrialProperties;
  unfilteredFeaturedLandProperties;
  unfilteredAllResidentialProperties;
  unfilteredAllCommercialProperties;
  unfilteredAllIndustrialProperties;
  unfilteredAllLandProperties;
  
  // google maps icons
  mapIcon = {
    url: '../../assets/icons/rounded-rectangle.svg',
    scaledSize: {
      width: 200,
      height: 50,
    }
  }
  markerWindow = null;
  markerClicked = false;

  featuredResidentialAPICalled: Promise<boolean>;
  featuredCommercialAPICalled: Promise<boolean>;
  featuredIndustrialAPICalled: Promise<boolean>;
  featuredLandAPICalled: Promise<boolean>;
  residentialAPICalled: Promise<boolean>;
  commercialAPICalled: Promise<boolean>;
  industrialAPICalled: Promise<boolean>;
  landAPICalled: Promise<boolean>;

  latitude = 27.5061111;
  longitude = -99.5072222;
  zoom = 12;
  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  p5: number = 1;

  typesToDisplay = {
    residentialProperties: true,
    commercialProperties: true,
    industrialProperties: true,
    landProperties: true
  }
  residentialFiltersDisplay = {
    priceFilterDisplay: false,//price is for all types but it is in this group for now
    bedFilterDisplay: false,
    bathFilterDisplay: false,
    typeFilterDisplay: false,
    sortFilterDisplay: false
  }
  commercialFiltersDisplay = {//industrial is grouped with this for now
    sizeFilterDisplay: false
    //more filter options
  }
  landFiltersDisplay = {
    areaFilterDisplay: false,
    //more filter options
  }

  minPriceControl = new FormControl();
  maxPriceControl = new FormControl();
  minBathControl = new FormControl();
  maxBathControl = new FormControl();
  sortFilterControl = new FormControl();

  residentialFilters = {
    minPrice: 0,
    maxPrice: 5000000,
    listOfPriceMinOptions: [],
    listOfPriceMaxOptions: [],
    listOfBathMinOptions: [],
    listOfBathMaxOptions: [],
    minBaths: 0,
    maxBaths: 10,
    bedsMin: 0
  }
  commercialFilters = {
    sizeMin: 0,
    sizeMax: 100000
  }
  landFilters = {
    areaMin: 0,
    areaMax: 1000
  }
  currentBedFilter: string = "";

  laredoBounds= new google.maps.LatLngBounds(
    new google.maps.LatLng(27.375255, -99.605297),
    new google.maps.LatLng(27.703629, -99.371649)
  );
  options = {
    componentRestrictions : {
      country: 'US'
    },
/*     bounds: this.laredoBounds,
    strictBounds: true */
  }

  isSmallScreen: boolean;
  displayMap = false;
  buttonText = "Map";

  constructor(private http: HttpClient, 
    private authService: AuthService, 
    private route: ActivatedRoute, 
    private router: Router,
    private loadPropertiesService: LoadPropertiesService,
    private breakpointObserver: BreakpointObserver) {
      this.isSmallScreen = breakpointObserver.isMatched('(max-width: 1200px)');
      breakpointObserver.observe([
        '(max-width: 1200px)'
      ]).subscribe(result => {
        if (result.matches) { this.isSmallScreen = true; }
        else { this.isSmallScreen = false; }
      });
    }

  isMapDisplay() { return (!this.isSmallScreen || this.displayMap); }
  isListDisplay() { return (!this.isSmallScreen || !this.displayMap); }
  toggleMapDisplay() { 
    this.displayMap = !this.displayMap;
    this.buttonText = (this.buttonText === "Map") ? "List" : "Map";
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  addFavorite(property: object) {
    if (!this.authService.isLoggedIn || !this.authService.favoriteListings.includes(property["ListingID"]) ) {
      property["favorite"] = false;
    } else {
      property["favorite"] = true;
    }
    if(property["Coordinates"]==undefined) {
      property["Coordinates"] = [1,1];
    }
    property["Price_Suffix"]= this.withSuffix(property["Price"]);
  }

  async ngOnInit() {
    
    // get the property listings now 
    if (!LoadPropertiesService.allPropertiesLoaded) await LoadPropertiesService.loadProperties(this.http);

    this.unfilteredFeaturedResidentialProperties = LoadPropertiesService.featuredResidentialProperties;
    this.unfilteredFeaturedResidentialProperties.forEach(property => { this.addFavorite(property); });
    this.featuredResidentialProperties = this.unfilteredFeaturedResidentialProperties;
    this.featuredResidentialAPICalled = Promise.resolve(true);

    this.unfilteredFeaturedCommercialProperties = LoadPropertiesService.featuredCommercialProperties;
    this.unfilteredFeaturedCommercialProperties.forEach(property => { this.addFavorite(property); });
    this.featuredCommercialProperties = this.unfilteredFeaturedCommercialProperties
    this.featuredCommercialAPICalled = Promise.resolve(true);


    this.unfilteredFeaturedIndustrialProperties = LoadPropertiesService.featuredIndustrialProperties;
    this.unfilteredFeaturedIndustrialProperties.forEach(property => { this.addFavorite(property); });
    this.featuredIndustrialProperties = this.unfilteredFeaturedIndustrialProperties;
    this.featuredIndustrialAPICalled = Promise.resolve(true);

    
    this.unfilteredFeaturedLandProperties = LoadPropertiesService.featuredLandProperties;
    this.unfilteredFeaturedLandProperties.forEach(property => { this.addFavorite(property); });
    this.featuredLandProperties = this.unfilteredFeaturedLandProperties;
    this.featuredLandAPICalled = Promise.resolve(true);

    this.featuredProperties = this.featuredResidentialProperties.concat(this.featuredCommercialProperties).concat(this.featuredIndustrialProperties).concat(this.featuredLandProperties)

    this.unfilteredAllResidentialProperties = LoadPropertiesService.allResidentialProperties;
    this.unfilteredAllResidentialProperties.forEach(property => { this.addFavorite(property); });
    this.allResidentialProperties = this.unfilteredAllResidentialProperties;
    this.residentialAPICalled = Promise.resolve(true);

    
    this.unfilteredAllCommercialProperties = LoadPropertiesService.allCommercialProperties;
    this.unfilteredAllCommercialProperties.forEach(property => { this.addFavorite(property); });
    this.allCommercialProperties = this.unfilteredAllCommercialProperties
    this.commercialAPICalled = Promise.resolve(true);

    
    this.unfilteredAllIndustrialProperties = LoadPropertiesService.allIndustrialProperties;
    this.unfilteredAllIndustrialProperties.forEach(property => { this.addFavorite(property); });
    this.allIndustrialProperties = this.unfilteredAllIndustrialProperties;
    this.industrialAPICalled = Promise.resolve(true);


    this.unfilteredAllLandProperties = LoadPropertiesService.allLandProperties;
    this.unfilteredAllLandProperties.forEach(property => { this.addFavorite(property); });
    this.allLandProperties = this.unfilteredAllLandProperties;
    this.landAPICalled = Promise.resolve(true);
    
    if(this.route.snapshot.paramMap.has('address')) {
      this.searchBar.nativeElement.value = this.route.snapshot.paramMap.get('address');
      this.handleAddressChange({formatted_address: this.route.snapshot.paramMap.get('address')});
    }
    if(this.route.snapshot.paramMap.has('sharedProperty')) {
      this.sharedProperty = this.route.snapshot.paramMap.get('sharedProperty');
    }
    
    //initialize options list for price
    let iter = Number(this.residentialFilters.minPrice)
    while(iter < this.residentialFilters.maxPrice) {
      this.residentialFilters.listOfPriceMaxOptions.push(iter)
      iter += 25000
    }
    this.residentialFilters.listOfPriceMinOptions = this.residentialFilters.listOfPriceMaxOptions
    //options list for baths
    iter = Number(this.residentialFilters.minBaths)
    while(iter < this.residentialFilters.maxBaths) {
      this.residentialFilters.listOfBathMaxOptions.push(iter)
      iter += 1
    }
    this.residentialFilters.listOfBathMinOptions = this.residentialFilters.listOfBathMaxOptions
  }
  ngAfterViewInit() {
    this.route.params.subscribe(params => {
      this.searchBar.nativeElement.value = this.route.snapshot.paramMap.get('address');
      if(this.searchBar.nativeElement.value!=="") {
        this.handleAddressChange({formatted_address: this.route.snapshot.paramMap.get('address')});
      }
    })
    setTimeout(()=>{this.showMap=true; setTimeout(()=>{this.showMarkers=true},100)},100)
    
  }
  ngOnDestroy () {
  }

  featuredPageChange(page: number) {
    var element = document.getElementById('featured-header');
    var headerOffset = 85;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    return page;
  }
  residentialPageChange(page: number) {
    var element = document.getElementById('residential-header');
    var headerOffset = 85;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    return page;
  }
  commercialPageChange(page: number) {
    var element = document.getElementById('commercial-header');
    var headerOffset = 85;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    return page;
  }
  industrialPageChange(page: number) {
    var element = document.getElementById('industrial-header');
    var headerOffset = 85;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    return page;
  }
  landPageChange(page: number) {
    var element = document.getElementById('land-header');
    var headerOffset = 85;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    return page;
  }
  priceFilterResidentialClicked() {
    this.residentialFiltersDisplay.bedFilterDisplay = false;
    this.residentialFiltersDisplay.bathFilterDisplay = false;
    this.residentialFiltersDisplay.typeFilterDisplay = false;
    this.residentialFiltersDisplay.sortFilterDisplay = false;
    this.landFiltersDisplay.areaFilterDisplay = false;
    this.residentialFiltersDisplay.priceFilterDisplay = this.residentialFiltersDisplay.priceFilterDisplay ? false : true
  }
  bedFilterClicked() {
    this.residentialFiltersDisplay.priceFilterDisplay = false;
    this.residentialFiltersDisplay.bathFilterDisplay = false;
    this.residentialFiltersDisplay.typeFilterDisplay = false;
    this.residentialFiltersDisplay.sortFilterDisplay = false;
    this.residentialFiltersDisplay.bedFilterDisplay ? this.residentialFiltersDisplay.bedFilterDisplay=false : this.residentialFiltersDisplay.bedFilterDisplay=true
  }
  typeFilterClicked() {
    this.residentialFiltersDisplay.priceFilterDisplay = false;
    this.residentialFiltersDisplay.bathFilterDisplay = false;
    this.residentialFiltersDisplay.bedFilterDisplay = false;
    this.residentialFiltersDisplay.sortFilterDisplay = false;
    this.landFiltersDisplay.areaFilterDisplay = false;
    this.residentialFiltersDisplay.typeFilterDisplay = this.residentialFiltersDisplay.typeFilterDisplay ? false : true
  }
  areaFilterClicked() {
    this.residentialFiltersDisplay.priceFilterDisplay = false;
    this.residentialFiltersDisplay.sortFilterDisplay = false;
    this.residentialFiltersDisplay.typeFilterDisplay = false;
    this.landFiltersDisplay.areaFilterDisplay = this.landFiltersDisplay.areaFilterDisplay ? false : true;
  }
  sizeFilterClicked() {
    this.residentialFiltersDisplay.priceFilterDisplay = false;
    this.residentialFiltersDisplay.sortFilterDisplay = false;
    this.residentialFiltersDisplay.typeFilterDisplay = false;
    this.commercialFiltersDisplay.sizeFilterDisplay = true;
  }
  bedFilterItemClicked(e) {
    this.currentBedFilter = e.target.id;
    let bedMinValue = 0;
    if(e.target.id==="bed-filter-one")
      bedMinValue = 1
    if(e.target.id==="bed-filter-two")
      bedMinValue = 2;
    if(e.target.id==="bed-filter-three")
      bedMinValue = 3
    if(e.target.id==="bed-filter-four")
      bedMinValue = 4
    this.residentialFilters.bedsMin = bedMinValue
    this.handleAddressChange({formatted_address: this.formattedAddress}) 
    this.applyResidentialFilters()
  }
  bathFilterClicked() {
    this.residentialFiltersDisplay.priceFilterDisplay = false;
    this.residentialFiltersDisplay.bedFilterDisplay = false;
    this.residentialFiltersDisplay.typeFilterDisplay = false;
    this.residentialFiltersDisplay.sortFilterDisplay = false;
    this.residentialFiltersDisplay.bathFilterDisplay = this.residentialFiltersDisplay.bathFilterDisplay ? false : true
  }
  sortFilterResidentialClicked() {
    this.residentialFiltersDisplay.bathFilterDisplay = false;
    this.residentialFiltersDisplay.priceFilterDisplay = false;
    this.residentialFiltersDisplay.bedFilterDisplay = false;
    this.residentialFiltersDisplay.typeFilterDisplay = false;
    this.landFiltersDisplay.areaFilterDisplay = false;
    this.residentialFiltersDisplay.sortFilterDisplay = this.residentialFiltersDisplay.sortFilterDisplay ? false : true
  }
  applyResidentialFilters() {
    this.featuredResidentialProperties = this.featuredResidentialProperties
      .filter(property => Number(property["Bedrooms"]) >= this.residentialFilters.bedsMin)
        .filter(property => Number(property["FullBaths"]) >= Number(this.residentialFilters.minBaths))
          .filter(property => Number(property["FullBaths"]) <= Number(this.residentialFilters.maxBaths))
            .filter(property => Number(property["Price"]) >= Number(this.residentialFilters.minPrice))
              .filter(property => Number(property["Price"]) <= Number(this.residentialFilters.maxPrice))
    this.allResidentialProperties = this.allResidentialProperties
      .filter(property => Number(property["Bedrooms"]) >= this.residentialFilters.bedsMin)
        .filter(property => Number(property["FullBaths"]) >= this.residentialFilters.minBaths)
          .filter(property => Number(property["FullBaths"]) <= this.residentialFilters.maxBaths)
            .filter(property => Number(property["Price"]) >= this.residentialFilters.minPrice)
              .filter(property => Number(property["Price"]) <= this.residentialFilters.maxPrice)
  }
  applyCommercialFilters() {
    this.featuredCommercialProperties = this.featuredCommercialProperties
      .filter(property => Number(property["Price"]) >= Number(this.residentialFilters.minPrice))
        .filter(property => Number(property["Price"]) <= Number(this.residentialFilters.maxPrice))
          .filter(property => Number(property["Area"]) >= Number(this.commercialFilters.sizeMin))
            .filter(property => Number(property["Area"]) <= Number(this.commercialFilters.sizeMax))
    this.allCommercialProperties = this.allCommercialProperties
      .filter(property => Number(property["Price"]) >= Number(this.residentialFilters.minPrice))
        .filter(property => Number(property["Price"]) <= Number(this.residentialFilters.maxPrice))
          .filter(property => Number(property["Area"]) >= Number(this.commercialFilters.sizeMin))
            .filter(property => Number(property["Area"]) <= Number(this.commercialFilters.sizeMax))
  }
  applyIndustrialFilters() {
    this.featuredIndustrialProperties = this.featuredIndustrialProperties
      .filter(property => Number(property["Price"]) >= Number(this.residentialFilters.minPrice))
        .filter(property => Number(property["Price"]) <= Number(this.residentialFilters.maxPrice))
        .filter(property => Number(property["Area"]) >= Number(this.commercialFilters.sizeMin))
          .filter(property => Number(property["Area"]) <= Number(this.commercialFilters.sizeMax))
    this.allIndustrialProperties = this.allIndustrialProperties
      .filter(property => Number(property["Price"]) >= Number(this.residentialFilters.minPrice))
        .filter(property => Number(property["Price"]) <= Number(this.residentialFilters.maxPrice))
          .filter(property => Number(property["Area"]) >= Number(this.commercialFilters.sizeMin))
            .filter(property => Number(property["Area"]) <= Number(this.commercialFilters.sizeMax))
  }
  applyLandFilters() {
    this.allLandProperties = this.allLandProperties
      .filter(property => Number(property["Price"]) >= Number(this.residentialFilters.minPrice))
        .filter(property => Number(property["Price"]) <= Number(this.residentialFilters.maxPrice))
          .filter(property => Number(property["Area"]) >= this.landFilters.areaMin)
            .filter(property => Number(property["Area"]) <= this.landFilters.areaMax)
    this.featuredLandProperties = this.featuredLandProperties
      .filter(property => Number(property["Price"]) >= Number(this.residentialFilters.minPrice))
        .filter(property => Number(property["Price"]) <= Number(this.residentialFilters.maxPrice))
          .filter(property => Number(property["Area"]) >= this.landFilters.areaMin)
            .filter(property => Number(property["Area"]) <= this.landFilters.areaMax)
  }

  minPriceFilterChange(e) {
    const value = (typeof(e) == "string") ? Number(e) : e.option.value;

    if(value < this.residentialFilters.maxPrice) {
      this.residentialFilters.minPrice = value
      this.residentialFilters.listOfPriceMaxOptions = []
      let iter = Number(this.residentialFilters.minPrice)
      while(iter < this.residentialFilters.maxPrice) {
        this.residentialFilters.listOfPriceMaxOptions.push(iter)
        iter += 25000
      }
      this.handleAddressChange({formatted_address: this.formattedAddress})
      this.applyResidentialFilters()
      this.applyCommercialFilters()
      this.applyIndustrialFilters()
      this.applyLandFilters() 
    }
  }
  maxPriceFilterChange(e) {
    const value = (typeof(e) == "string") ? Number(e) : e.option.value;

    if(value > this.residentialFilters.minPrice) {
      this.residentialFilters.maxPrice = value
      this.residentialFilters.listOfPriceMinOptions = []
      let iter = Number(0)
      while(iter < this.residentialFilters.maxPrice) {
        this.residentialFilters.listOfPriceMinOptions.push(iter)
        iter += 25000
      }
      this.handleAddressChange({formatted_address: this.formattedAddress})
      this.applyResidentialFilters()
      this.applyCommercialFilters()
      this.applyIndustrialFilters()
      this.applyLandFilters()
    }
  }
  minBathFilterChange(e) {
    if(e.option.value < this.residentialFilters.maxBaths) {
      this.residentialFilters.minBaths = e.option.value
      this.residentialFilters.listOfBathMaxOptions = []
      let iter = Number(this.residentialFilters.minBaths)
      while(iter < 10) {
        this.residentialFilters.listOfBathMaxOptions.push(iter)
        iter += 1
      }
      this.handleAddressChange({formatted_address: this.formattedAddress})
      this.applyResidentialFilters()
    }
  }
  maxBathFilterChange(e) {
    if(e.option.value > this.residentialFilters.minBaths) {
      this.residentialFilters.maxBaths = e.option.value
      this.residentialFilters.listOfBathMinOptions = []
      let iter = Number(0)
      while(iter < this.residentialFilters.maxBaths) {
        this.residentialFilters.listOfBathMinOptions.push(iter)
        iter += 1
      }
      this.handleAddressChange({formatted_address: this.formattedAddress})
      this.applyResidentialFilters()
    }
  }
  minAreaFilterChange(e) {
    let val = e.target.value
    if(val === '') {
      this.landFilters.areaMin = 0
    }
    else {
      this.landFilters.areaMin = e.target.value;
    }
    this.handleAddressChange({formatted_address: this.formattedAddress})   

    this.applyLandFilters();
  }
  maxAreaFilterChange(e) {
    let val = e.target.value
    if(val === '') {
      this.landFilters.areaMin = 1000
    }
    else {
      this.landFilters.areaMin = e.target.value;
    }
    this.handleAddressChange({formatted_address: this.formattedAddress})
    this.applyLandFilters();
  }
  minSizeFilterChange(e) {
    let val = e.target.value
    if(val === '') {
      this.commercialFilters.sizeMin = 0
    }
    else {
      this.commercialFilters.sizeMin = e.target.value;
    }
    this.handleAddressChange({formatted_address: this.formattedAddress})   
    this.applyCommercialFilters();
    this.applyIndustrialFilters();
  }
  maxSizeFilterChange(e) {
    let val = e.target.value
    if(val === '') {
      this.landFilters.areaMin = 1000
    }
    else {
      this.landFilters.areaMin = e.target.value;
    }
    this.handleAddressChange({formatted_address: this.formattedAddress}) 
    this.applyLandFilters();
  }
  getBedFilterClass(id: string) {
    if (id === this.currentBedFilter)
      return "filter-increment-item-selected";
    else
      return "filter-increment-item";
  }
  displayFilter(value) {
    return (value === null) ? "" : value;
  } 
  clearPriceFilter() {
    this.residentialFilters.minPrice = 0;
    this.residentialFilters.maxPrice = 5000000;

    this.minPriceControl.setValue(null);
    this.maxPriceControl.setValue(null);

    this.handleAddressChange({formatted_address: this.formattedAddress}) 
    this.applyResidentialFilters();
    this.applyCommercialFilters();
    this.applyIndustrialFilters();
    this.applyLandFilters();
    // TODO: Modify this function to accommodate for the rest of the properties
    let iter = Number(0)
    this.residentialFilters.listOfPriceMaxOptions = []
    while(iter < this.residentialFilters.maxPrice) {
      this.residentialFilters.listOfPriceMaxOptions.push(iter)
      iter += 25000
    }
    this.residentialFilters.listOfPriceMinOptions = this.residentialFilters.listOfPriceMaxOptions

  }
  clearBathsFilter() {
    this.residentialFilters.minBaths = 0;
    this.residentialFilters.maxBaths = 10;
    this.minBathControl.setValue(null);
    this.maxBathControl.setValue(null);
    this.handleAddressChange({formatted_address: this.formattedAddress})
    this.applyResidentialFilters();
    //options list for baths
    let iter = Number(this.residentialFilters.minBaths)
    while(iter < this.residentialFilters.maxBaths) {
      this.residentialFilters.listOfBathMaxOptions.push(iter)
      iter += 1
    }
    this.residentialFilters.listOfBathMinOptions = this.residentialFilters.listOfBathMaxOptions
  }
  clearSortFilter() {
    this.featuredResidentialProperties = this.unfilteredFeaturedResidentialProperties;
    this.featuredCommercialProperties = this.unfilteredFeaturedCommercialProperties;
    this.featuredIndustrialProperties = this.unfilteredFeaturedIndustrialProperties;
    this.featuredLandProperties = this.unfilteredFeaturedLandProperties;

    this.allResidentialProperties = this.unfilteredAllResidentialProperties;
    this.allCommercialProperties = this.unfilteredAllCommercialProperties;
    this.allIndustrialProperties = this.unfilteredAllIndustrialProperties;
    this.allLandProperties = this.unfilteredAllLandProperties;
    this.sortFilterControl.setValue(null);
    this.handleAddressChange({formatted_address: this.formattedAddress})
    this.applyResidentialFilters();
    this.applyCommercialFilters();
    this.applyIndustrialFilters();
    this.applyLandFilters();
  }
  clearBedsFilter() {
    this.residentialFilters.bedsMin = 0
    this.currentBedFilter = "";
    this.handleAddressChange({formatted_address: this.formattedAddress})
    this.applyResidentialFilters();
  }
  clearAreaFilter() {
    this.landFilters.areaMin = 0;
    this.landFilters.areaMax = 1000;
    this.handleAddressChange({formatted_address: this.formattedAddress}) 
    this.applyLandFilters();
  }
  clearSizeFilter() {
    this.commercialFilters.sizeMin = 0;
    this.commercialFilters.sizeMax = 100000;
    this.handleAddressChange({formatted_address: this.formattedAddress}) 
    this.applyLandFilters();
  }
  getCurrentTypeFilter() {
    if ( this.typesToDisplay.residentialProperties && this.typesToDisplay.commercialProperties && this.typesToDisplay.industrialProperties && this.typesToDisplay.landProperties) {
      return "all";
    }
    else {
      if (this.typesToDisplay.residentialProperties) return "residential";
      if (this.typesToDisplay.commercialProperties) return "commercial";
      if (this.typesToDisplay.industrialProperties) return "industrial";
      if (this.typesToDisplay.landProperties) return "land";
    }
  }
  filterTypeChange(e) {
    this.showMarkers = false;
    setTimeout(()=>{this.showMarkers=true},200)
    if(e.value=="residential") {
      this.typesToDisplay.residentialProperties = true;
      this.typesToDisplay.commercialProperties = false;
      this.typesToDisplay.industrialProperties = false;
      this.typesToDisplay.landProperties = false;
    }
    if(e.value=="commercial") {

      this.typesToDisplay.residentialProperties = false;
      this.typesToDisplay.commercialProperties = true;
      this.typesToDisplay.industrialProperties = false;
      this.typesToDisplay.landProperties = false; 
    }
    if(e.value=="industrial") {
      this.typesToDisplay.residentialProperties = false;
      this.typesToDisplay.commercialProperties = false;
      this.typesToDisplay.industrialProperties = true;
      this.typesToDisplay.landProperties = false; 

    }
    if(e.value=="land") {
      this.typesToDisplay.residentialProperties = false;
      this.typesToDisplay.commercialProperties = false;
      this.typesToDisplay.industrialProperties = false;
      this.typesToDisplay.landProperties = true; 

    }
    if(e.value=="allTypes") {
      this.typesToDisplay.residentialProperties = true;
      this.typesToDisplay.commercialProperties = true;
      this.typesToDisplay.industrialProperties = true;
      this.typesToDisplay.landProperties = true; 

    }
  }
  sortByPrice(e) {
    if(e.value=="ascending") {
      this.featuredResidentialProperties.sort((a,b) => a["Price"] - b["Price"]);
      this.featuredCommercialProperties.sort((a,b) => a["Price"] - b["Price"]);
      this.featuredIndustrialProperties.sort((a,b) => a["Price"] - b["Price"]);
      this.featuredLandProperties.sort((a,b) => a["Price"] - b["Price"]);

      this.allResidentialProperties.sort((a,b) => a["Price"] - b["Price"]);
      this.allCommercialProperties.sort((a,b) => a["Price"] - b["Price"]);
      this.allIndustrialProperties.sort((a,b) => a["Price"] - b["Price"]);
      this.allLandProperties.sort((a,b) => a["Price"] - b["Price"])
    }
    else if(e.value=="descending") {
      this.featuredResidentialProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();
      this.featuredCommercialProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();
      this.featuredIndustrialProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();
      this.featuredLandProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();

      this.allResidentialProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();
      this.allCommercialProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();
      this.allIndustrialProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();
      this.allLandProperties.sort((a,b) => a["Price"] - b["Price"]).reverse();
    }
  }
  public handleAddressChange(address: any) {
    this.formattedAddress = address["formatted_address"];
    var addressFilter: string;

    if(this.formattedAddress === "Search") {
      this.clearAddressFilter();
    }
    else {
      this.showMarkers = false;//needed to reduce marker cluster lag
      setTimeout(()=>{this.showMarkers=true},500)
      addressFilter = this.formattedAddress.substr(0, this.formattedAddress.length - 5);
       const addressRegExp: RegExp = new RegExp(`.*${addressFilter.toLowerCase()}.*`);
       console.log(`The regexp is: .*${addressFilter.toLowerCase()}.*`);
      if (this.residentialAPICalled) {
        this.allResidentialProperties = 
        this.unfilteredAllResidentialProperties
            .filter((property: Object) => {
              const address = `${property["Address"]["Number"]} ${property["Address"]["Street"]}, ${property["Address"]["City"]}, ${property["Address"]["State"]} ${property["Address"]["Zip"]}, USA ${property["ListingID"]}`.toLowerCase();
              console.log(`The address to check against the regexp is: ${address}`);
              return addressRegExp.test(address);
            });
      }
      
      if (this.commercialAPICalled) {
/*         this.featuredCommercialProperties = 
        this.unfilteredFeaturedCommercialProperties
            .filter((property: Object) => {
              const address = `${property["Address"]["Number"]} ${property["Address"]["Street"]}, ${property["Address"]["City"]}, ${property["Address"]["State"]} ${property["Address"]["Zip"]} ${property["ListingID"]}`.toLowerCase();
              return addressRegExp.test(address);
                    }
              ); */
  
        this.allCommercialProperties = 
        this.unfilteredAllCommercialProperties
            .filter((property: Object) => {
              const address = `${property["Address"]["Number"]} ${property["Address"]["Street"]}, ${property["Address"]["City"]}, ${property["Address"]["State"]} ${property["Address"]["Zip"]}, USA ${property["ListingID"]}`.toLowerCase();
              return addressRegExp.test(address);
            });
      }
      if (this.industrialAPICalled) {
/*         this.featuredIndustrialProperties = 
        this.unfilteredFeaturedIndustrialProperties
            .filter((property: Object) => {
              const address = `${property["Address"]["Number"]} ${property["Address"]["Street"]}, ${property["Address"]["City"]}, ${property["Address"]["State"]} ${property["Address"]["Zip"]} ${property["ListingID"]}`.toLowerCase();
              return addressRegExp.test(address);
            });
   */
        this.allIndustrialProperties = 
        this.unfilteredAllIndustrialProperties
            .filter((property: Object) => {
              const address = `${property["Address"]["Number"]} ${property["Address"]["Street"]}, ${property["Address"]["City"]}, ${property["Address"]["State"]} ${property["Address"]["Zip"]}, USA ${property["ListingID"]}`.toLowerCase();
              return addressRegExp.test(address);
            });
      }
      if (this.landAPICalled) {
/*         this.featuredLandProperties = 
        this.unfilteredFeaturedLandProperties
            .filter((property: Object) => {
              const address = `${property["Address"]["Number"]} ${property["Address"]["Street"]}, ${property["Address"]["City"]}, ${property["Address"]["State"]} ${property["Address"]["Zip"]} ${property["ListingID"]}`.toLowerCase();
              return addressRegExp.test(address);
            });
   */
        this.allLandProperties = 
        this.unfilteredAllLandProperties
            .filter((property: Object) => {
                      const address = `${property["Address"]["Number"]} ${property["Address"]["Street"]}, ${property["Address"]["City"]}, ${property["Address"]["State"]} ${property["Address"]["Zip"]}, USA ${property["ListingID"]}`.toLowerCase();
                      return addressRegExp.test(address);
            });
      }
    }
  }
  handleSearch(e: KeyboardEvent) {
    const listingID: string = e['target']['value'];
    this.showMarkers = false;//needed to reduce marker cluster lag
    setTimeout(()=>{this.showMarkers=true},500)
    if (this.residentialAPICalled) {
      this.allResidentialProperties = 
      this.unfilteredAllResidentialProperties
          .filter((property: Object) => { return listingID === property["ListingID"]; });
    }
    
    if (this.commercialAPICalled) {
      this.allCommercialProperties = 
      this.unfilteredAllCommercialProperties
          .filter((property: Object) =>  { return listingID === property["ListingID"]; });
    }
    if (this.industrialAPICalled) {
      this.allIndustrialProperties = 
      this.unfilteredAllIndustrialProperties
          .filter((property: Object) => { return listingID === property["ListingID"]; });
    }
    if (this.landAPICalled) {
      this.allLandProperties = 
      this.unfilteredAllLandProperties
          .filter((property: Object) => { return listingID === property["ListingID"]; });
    }
  }
  clearAddressFilter() {
    this.formattedAddress = "Search";
    this.searchBar.nativeElement.value = '';
    this.featuredResidentialProperties = this.unfilteredFeaturedResidentialProperties;
    this.featuredCommercialProperties = this.unfilteredFeaturedCommercialProperties;
    this.featuredIndustrialProperties = this.unfilteredFeaturedIndustrialProperties;
    this.featuredLandProperties = this.unfilteredFeaturedLandProperties;

    this.allResidentialProperties = this.unfilteredAllResidentialProperties;
    this.allCommercialProperties = this.unfilteredAllCommercialProperties;
    this.allIndustrialProperties = this.unfilteredAllIndustrialProperties;
    this.allLandProperties = this.unfilteredAllLandProperties;

    this.showMarkers = false;
    setTimeout(()=>{this.showMarkers=true},500)
    this.applyResidentialFilters();
    this.applyCommercialFilters();
    this.applyIndustrialFilters();
    this.applyLandFilters();
  }
  selectMarker(infoWindow) {
    if(this.markerWindow) {
      this.markerWindow.close();
      this.markerWindow = infoWindow;
      this.markerWindow.open();
    }
    else {
      this.markerWindow = infoWindow;
      this.markerWindow.open();
    }
  }
  clickedMap() {
    if(this.markerWindow) {
      this.markerWindow.close();
      this.markerWindow = null;
    }
  }
  hoverMarker(infoWindow) {
    if(!this.markerWindow) {
      infoWindow.open()
    }
  }
  hoverMarkerExit(infoWindow) {
    if(!this.markerWindow) {
      infoWindow.close();
    }
  }
  //function for transforming number
  withSuffix(v) {
    let value = Number(Number(v).toFixed());
    let transformVal = ""
    if(value>=1000000) {
      transformVal=(value/1000000).toFixed()+"M"
      return transformVal
    }
    if(value>=1000) {
      transformVal=(value/1000).toFixed()+"K"
      return transformVal
    }
    return v;
  }

}
