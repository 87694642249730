import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogClose, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'dialog-elements-example-dialog',
    styleUrls: ['login.component.css'],
    templateUrl: 'forgot.password.component.html',
  })
  export class ForgotPasswordComponent implements OnInit {
    @Output() forgotPasswordCloseModal = new EventEmitter<string>();
    forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required])
    })
    resetEmailSent = false;
    invalidEmailProvided = false;
    constructor( public authService: AuthService ) { }
    ngOnInit() { }
    async forgotPassword() {
      if (this.forgotPasswordForm.valid) {
        try {
          await this.authService.forgotPassword(this.forgotPasswordForm.get("email").value)
          this.resetEmailSent = true;
          this.invalidEmailProvided = false;
        }
        catch(err) {
          if(err["code"]=== "auth/user-not-found") {
            this.invalidEmailProvided = true;
            this.resetEmailSent = false;
          }
        }
      }
    }
  }