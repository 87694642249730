<div class="container">
    <div class="top-row">
        <h3>Get A Personal Estimate</h3>
        <span [mat-dialog-close] class="cross">&times;</span>
    </div>
    <form [formGroup]="contactForm" (ngSubmit)="submitForm(contactForm,formDirective)" #formDirective="ngForm">
        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName = "firstName" required>
            <mat-error *ngIf="contactForm.controls['firstName'].hasError('required')">Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName = "lastName" required>
            <mat-error *ngIf="contactForm.controls['lastName'].hasError('required')">Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName = "email" required>
            <mat-error *ngIf="contactForm.controls['email'].hasError('required')">Enter your email </mat-error>
            <mat-error *ngIf="contactForm.controls['email'].hasError('email')">This does not seem to be a valid email</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput type="tel" formControlName = "phone" required>
            <mat-error *ngIf="contactForm.controls['phone'].hasError('required')">Required</mat-error>
            <mat-error *ngIf="contactForm.controls['phone'].hasError('pattern')">Enter a valid phone number (###-###-####)</mat-error>
        </mat-form-field>
        <button id="submit-info" type="submit" color="accent" mat-flat-button [disabled]="!contactForm.valid">Submit</button>
        <span *ngIf="failedSubmit">Failed to submit form, please try again later.</span>
    </form>
</div>