<img class="lg" src="../../assets/contact-us.jpg">
<img class="sm" src="../../assets/contact-us.jpg">
<div class="Title">
    <h1>Send us a message!</h1>
</div>
<form [formGroup]="contactForm" (ngSubmit)="submitForm(contactForm,formDirective)" #formDirective="ngForm">
    <div class="form-fields">
        <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName = "firstName" required>
                <mat-error *ngIf="contactForm.controls['firstName'].hasError('required')">Required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput matInput formControlName = "lastName" required>
                <mat-error *ngIf="contactForm.controls['lastName'].hasError('required')">Required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName = "email" required>
                <mat-error *ngIf="contactForm.controls['email'].hasError('required')">Required </mat-error>
                <mat-error *ngIf="contactForm.controls['email'].hasError('email')">This does not seem to be a valid email</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput type="tel" formControlName = "phone" required>
                <mat-error *ngIf="contactForm.controls['phone'].hasError('required')">Required</mat-error>
                <mat-error *ngIf="contactForm.controls['phone'].hasError('pattern')">Enter a valid phone number (###-###-####)</mat-error>
            </mat-form-field>
        </div>
        <div class="col2">
            <mat-form-field  appearance="outline">
                <mat-label>Reason for contact</mat-label>
                <mat-select formControlName="reason" class = "mat-selected-content">
                    <mat-option>--</mat-option>
                    <mat-option value="Buy">Buying/Leasing a property</mat-option>
                    <mat-option value="Sell">Selling/Leasing my property</mat-option>
                    <mat-option value="Query">Other questions about the market</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" >
                <mat-label>Message</mat-label>
                <textarea formControlName="message" cdkTextareaAutosize matInput></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="space-button">
        <button type="submit" mat-raised-button color="primary" [disabled]="!contactForm.valid">Send</button>
    </div>
    <span *ngIf="failedSubmit">Failed to submit form, please try again later.</span>
</form>


