<div class="signup-modal">
    <span [mat-dialog-close] class="cross">&times;</span>

    <div class="intro">
        <h2>Create an account</h2>
        <p>Save your favorite properties and improve your home searching experience</p>
        <p>Already have an account? <a (click)="openSignIn()">Login</a></p>
    </div>

    <div class="separator">Sign up with Email</div>

    <form [formGroup]="signupForm" (ngSubmit)="submitForm()">
        <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>Email</mat-label>
            <input matInput formControlName = "email" required>
            <mat-error *ngIf="signupForm.controls['email'].hasError('required')">Enter your email </mat-error>
            <mat-error *ngIf="signupForm.controls['email'].hasError('email')">This does not seem to be a valid email</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>Password</mat-label>
            <input matInput [type]="passwordFormHide ? 'password' : 'text'" formControlName = "password" required>
            <button mat-icon-button matSuffix (click)="passwordFormHide = !passwordFormHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordFormHide">
                <mat-icon>{{passwordFormHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="signupForm.controls['password'].hasError('required')">Choose a password! </mat-error>
            <mat-error *ngIf="signupForm.controls['password'].hasError('pattern')">Password must be minimum 8 characters and have at least 1 uppercase letter, 1 lowercase letter, and 1 number </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="passwordFormHide ? 'password' : 'text'" formControlName = "confirmPassword" required>
            <button mat-icon-button matSuffix (click)="passwordFormHide = !passwordFormHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordFormHide">
                <mat-icon>{{passwordFormHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="signupForm.controls['confirmPassword'].hasError('required')">Enter your password again </mat-error>
            <mat-error *ngIf="signupForm.errors?.failedPasswordMatchValidator">Passwords do not match!</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName = "firstName" required>
            <mat-error *ngIf="signupForm.controls['firstName'].hasError('required')">First name required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput matInput formControlName = "lastName" required>
            <mat-error *ngIf="signupForm.controls['lastName'].hasError('required')">Last name required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput type="tel" formControlName = "phone" required>
            <mat-error *ngIf="signupForm.controls['phone'].hasError('required')">Phone required</mat-error>
        </mat-form-field>
        <mat-form-field  appearance="outline">
            <mat-label>Working With An Agent?</mat-label>
            <mat-select formControlName="agent">
                <mat-option *ngFor="let agent of agents" [value]="agent">
                  {{agent}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="signupForm.controls['agent'].hasError('required')">Select an agent</mat-error>
        </mat-form-field>
        <span class="invalid-signup" *ngIf="invalidSignupAttempt || otherError"> {{ getErrorMessage() }}</span>
        <button class="signup-btn" type="submit" mat-flat-button color="accent" [disabled]="!signupForm.valid" >Sign up</button>
    </form>
</div>
