import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css']
})
export class ShareModalComponent implements OnInit {
  @Input() property;
  shareLink;
  copiedStatus = "Copy"
  isCopied = false;
  constructor( @Inject(MAT_DIALOG_DATA) public data) { 
    this.property = data;
  }
  ngOnInit(): void {
    let address = this.property["Address"]["Number"] + ' ' + this.property["Address"]["Street"] + ', ' + this.property["Address"]["City"] + ', ' + this.property["Address"]["State"]
    this.shareLink = "gabalirealty.com/featured-properties;address=" + address + ';sharedProperty=' + this.property["ListingID"]
  }

}
